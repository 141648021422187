@import '../../scss/base.scss';

.login-wrapper {
    height: 100vh;
    @include FlexCenterCenter;
    background: url("https://nationscdn.azureedge.net/nb-container/OnBoarding/login-bg.png") center center;
    background-size: cover;
    flex-direction: column;

    .logo {
        max-width: 236px;
        margin: 0 auto 48px;
    }

    .login-form-sec {
        background: $white-color;
        border-radius: 4px;
        padding: 72px 56px;
        width: 94%;
        max-width: 592px;


        .login-head {
            margin-bottom: 60px;

            h2 {
                font-weight: 500;
                margin-bottom: 24px;
                font-size: 40px;
                line-height: 32px;
                color: $woodsmoke-color;
            }

            p {
                font-size: 20px;
                line-height: 28px;
                color: #767676
            }
        }

        button {
            width: 100%;

            &:not(:last-child) {
                margin: 0 0 29px !important;
            }
        }

        a {
            font-weight: 500;
            font-size: 18px;
            color: #255798;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .login-user {
        .login-head {
            margin: 0 0 40px !important;
        }

        button {
            justify-content: flex-start;
            border-radius: 4px;
            border: 3px solid transparent;
            padding: 24px 40px;
            font-size: 24px;
            line-height: 30px;
            font-weight: 500;
            color: $woodsmoke-color;
            box-shadow: none !important;

            img {
                margin: 0 16px 0 0;
                max-width: 34px;
            }

            &.manager {
                background: #7AD0F01A;
                border-color: #7AD0F0;

                &:hover {
                    background: #7AD0F04D;
                    border-color: #7AD0F0;
                }
            }

            &.admin {
                background: #FBF3D24D;
                border-color: #FABE2E;

                &:hover {
                    background: #FBF3D299;
                    border-color: #FABE2E;
                }
            }
        }
    }

    .form-floating {
        margin: 0 0 48px;
    }
}
