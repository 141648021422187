@import '../../scss/base.scss';

.benefit-config {
    h5 {
        font-size: 24px;
        line-height: 29px;
        font-weight: 600;
        margin: 0 0 16px;
    }

    .benefits {
        h5 {
            margin: 32px 0 10px 24px;

            &:first-child {
                margin-top: 0;
            }
        }
    }
}

.special {
    background: #BE33FF;
    border-radius: 8px;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.01em;
    font-weight: 700;
    color: $white-color;
    padding: 4px 8px;
    display: inline-block;
    margin: 0 16px 0 0;
    min-width: 24px;
    min-height: 24px;
}

.benefit-count {
    button {
        background: $athensgray-color !important;
        border-radius: 20px;
        font-size: 14px;
        line-height: normal;
        font-weight: 600;
        color: $woodsmoke-color !important;
        padding: 4px 10px 3px;
        display: inline-block;
        margin: 0 !important;
        min-width: inherit;
        min-height: inherit;
        border: none;
        box-shadow: none !important;
    }

    .dropdown-menu {
        padding: 24px;
        width: 235px;
        cursor: default;

        span {
            white-space: nowrap;
        }

        .benefit-icon {
            margin: 0 0 12px;
        }

        .dropdown-item-text {
            background: none;
            padding: 0;
            @include FlexCenter;
            justify-content: flex-start;
            margin: 0 0 12px;
            border: none;

            &:last-child {
                margin: 0
            }

            img {
                margin: 0 6px 0 0;
            }
        }

        &.show {
            transform: translateY(30px) !important
        }
    }
}


.benefits-list {
    width: calc(100% + 48px) !important;
    margin: 0 0 0 -24px;
}

.rc-scrollbars-container {
    .benefit-details {
        width: 100%;
        margin: 0;
    }
}

.benefit-details {
    width: calc(100% + 48px);
    list-style: none;
    margin: 0 0 0 -24px;
    padding: 0;
    position: relative;
    z-index: 10;
    .show-popup{
        img{
            width:20px;
        }
    }

    li {
        margin: 0 0 2px;

        a {
            @include FlexCenterBetween;
            padding: 12px 24px;
            color: $woodsmoke-color;
            text-decoration: none;
            font-weight: 500;
            margin: 0;
            cursor: pointer;
            position:relative;
            &:after{
                background: transparent;
                right: -24px;
                content: '';
                position: absolute;
                height: 100%;
            
            }

            .timer {
                margin: 0 8px 0 0;
            }

            .benefit-icon {
                width: 16px;
                height: 16px;
                @include FlexCenterBetween;
                margin: 0 8px 0 0;
            }

            .price-options {
                margin: 0 12px 0 0;
            }

            .benefitaction {
                opacity: 0;
                @include Transition2;
            }

            &:hover, &.active {
                background: $neutralblue-color;

                .benefitaction {
                    opacity: 1;
                }
                &:after{
                    background:$neutralblue-color;
                }
            }

            .benefit-pride {
                display: none;

                @include breakpoint (large-desktop) {
                    display: flex;
                }
            }
        }
    }

    .benefits-inner-list {
        @include FlexCenter;
    }
}

.add-carrier {
    h5 {
        margin: 30px 0 0 0;

        &:first-child {
            margin: 0;
        }
    }

    .rc-scrollbars-view {
        padding:0;
    }

    .modal-footer {
        button {
            @include FlexCenter;
            justify-content: start;
            width: 100%;
            margin: 0;
            padding: 12px 16px;
            height: 100%;
            text-align: left;

            img {
                margin: 0 8px 0 0;
            }
        }
    }

    .benefit-content-section {
        .rc-scrollbars-view {
            padding: 16px;
        }
    }
}

.add-catalog {
    .modal-dialog {
        width: 1040px;
        margin: auto;
    }
}
