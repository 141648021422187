@import "base";
$questionnaire-content-height: 180px;

.modal {
    backdrop-filter: blur(2.5px);
    -webkit-backdrop-filter: blur(2.5px);
    overflow: hidden;

    .modal-header {
        padding: 24px;

        .btn-close, &.btn-close:focus {
            outline: none;
            box-shadow: none;
        }
    }

    .modal-title {
        margin: 0;
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: 31px;
    }

    ul, ol {
        li {
            color: $nevada-color;
        }
    }

    h5 {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        color: $woodsmoke-color;
        margin: 0 0 5px;
    }

    p {
        color: $nevada-color;

        &:last-child {
            margin: 0;
        }
    }

    .modal-check {
        margin: 16px 0 0;
        @include FlexCenter;

        .form-check {
            margin: 0;
        }
    }

    .modal-dialog {
        margin: auto;
        height: 100%;
    }
    /*  .modal-content {
        border-radius: 8px;
        border: none;
        overflow: hidden;
    }

    .modal-header {
        background-color: $primary-color;
        border: none;
        padding: 1rem 1.5rem;

        .modal-title {
            color: $white-color;
            font-size: 20px;
            font-weight: 700;
            line-height: 24px;
            margin: 0;
        }

        .btn-close {
            background: url(https://nationscdn.azureedge.net/otc-container/images/close-white.svg) no-repeat center;
            opacity: 1;
            box-shadow: none !important;
            padding: 0;
            background-size: 100%;
            width: 22px;
            height: 22px;
        }
    }

    .modal-body {
        padding: 1rem 1.5rem 1.5rem;

        p {
            margin: 0 0 5px;
        }
    }*/
    .modal-body {
        padding: 16px 24px;
        //max-height: 670px;
        //overflow: auto;

        &::-webkit-scrollbar {
            height: 8px;
            width: 8px;
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background: $cadetblue-color;
            border-radius: 8px;
        }

        [data-target="slide-questionnaire"] {
            height: 0;
            overflow: hidden;
            visibility: hidden;
        }

        .features-search {

            .form-control {
                height: 56px;
            }
        }

        .questionnaire-down {
            animation: questionnaire-down 0.5s linear both;
        }

        .questionnaire-up {
            animation: questionnaire-up 0.5s linear both;
        }
        //SubCHild
        .questionnaire-down-subchild {
            animation: questionnaire-down 0.5s linear both;
        }

        .questionnaire-up-subchild {
            animation: questionnaire-up 0.5s linear both;
        }

        @keyframes questionnaire-down {
            0% {
                visibility: hidden;
                height: 0;
            }

            95% {
                visibility: visible;
                height: $questionnaire-content-height;
            }

            100% {
                visibility: visible;
                height: auto;
            }
        }

        @keyframes questionnaire-up {
            from {
                visibility: visible;
                height: $questionnaire-content-height;
            }

            to {
                visibility: hidden;
                height: 0;
            }
        }
    }

    .modal-footer {
        padding: 24px;

        .disabled {
            img {
                filter: grayscale(100%);
            }
        }
    }

    &.filters-modal {
        .input-box {
            margin: 0 0 16px;

            &:last-child {
                margin: 0;
            }

            label {
                color: $woodsmoke-color;
            }

            .form-check {
                @include FlexCenter;

                .form-check-input {
                    margin-top: 0;
                }
            }
        }
    }

    &.Launchwizard {
        .modal-body, .modal-content {
            overflow: inherit;
        }
    }

    @include breakpoint (tablet-landscape-start) {
        &.modal-large {
            .modal-content {
                max-width: 930;
                width: 100%;
                margin: auto;
            }
        }
    }

    @include breakpoint (portrait-tablet-end) {
        padding: 0 !important;

        &.bottom {
            .modal-content {
                max-width: 100%;
                position: fixed;
                bottom: 0;
                top: auto;
                border-radius: 8px 8px 0 0;
            }

            .modal-dialog {
                margin: auto;
                max-width: 100%;
            }
        }
        /*&.bottom {
            .modal-dialog {
                -webkit-transform: translate3d(0, 125%, 0);
                transform: translate3d(0, 125%, 0);
                max-width: 100%;
                height: 100vh;
            }

            .modal-content {
                max-width: 100%;
                position: fixed;
                bottom: 0;
                border-radius: 8px 8px 0 0;
            }

            .modal-footer {
                bottom: 0;
                border-radius: 0;
            }

            &.show {
                .modal-dialog {
                    -webkit-transform: translate3d(0, 0, 0);
                    transform: translate3d(0, 0, 0);
                }
            }
        }*/
        .modal-header {
            padding: 12px 16px;
        }

        .modal-title {
            font-size: 18px;
            line-height: normal;
        }



        .modal-body {
            padding: 16px;

            .semect-member {
                margin: 0 0 20px;
            }

            h6, p {
                font-size: 14px !important;
                line-height: 16px !important;
            }

            .section-outline {
                padding: 8px 12px;

                .form-check {
                    margin: 0 8px 0 0;
                }
            }

            label {
                font-size: 14px;
                line-height: normal;

                span {
                    font-size: 12px !important;
                    line-height: 16px !important;
                    letter-spacing: 0;
                }
            }
        }

        .modal-footer {
            background: $white-color;
            width: 100%;
            padding: 16px;

            button {
                font-size: 14px;
                margin: 0 24px 0 0;
            }
        }
    }

    @include breakpoint (mobile) {
        .modal-body {
            //max-height: 65vh;
            padding-bottom: 15px;
            //max-height: 400px;
            //overflow: auto;
        }
        /*.modal-footer {
            position: fixed;
        }*/
    }
}
.acknowledge {
    font-size: 18px;
    line-height: 22px;
    color: $woodsmoke-color;
    font-weight: 500;
    margin: 0 0 0 3px;
}

.plans-overrides {
    margin: 28px 0 8px;

    p {
        font-size: 18px;
        line-height: 22px;
        color: $woodsmoke-color;
        font-weight: 600;
    }
}

.noborder-list {
    li {
        border: none;
        padding: 0;
    }

    &.plans-list {
        li {
            font-size: 18px;
            line-height: 26px;
            color: $woodsmoke-color;
        }

        &.inner-plans {
            li {
                @include FlexCenter;
            }
        }
    }
}

.border-list {
    border-color: $athensgray-color;
    border-style: solid;
    border-width: 4px;
    padding: 10px
}

.modal-list {
    background: $white-color;
    border: 1px solid $athensgray-color;
    border-radius: 8px;
    padding: 20px;
    --bs-list-group-border-color: none;

    .list-group-item {
        color: $woodsmoke-color;
        padding: 6px 0;
    }

    img {
        margin: 0 8px 0 0;
    }
}
.mh-200 {
    min-height: 200px;
}