@import "base";

.loading-skeleton {
    pointer-events: none;
    animation: loading-skeleton 1s infinite alternate;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    overflow: hidden;

    h1, h2, h3, h4, h5, h6, p, li, a, span, .btn, label, form-check-input, .form-control, img, button, td span, th, textarea {
        color: transparent !important;
        appearance: none !important;
        -webkit-appearance: none !important;
        background-color: $light-grey-color !important;
        border-color: $light-grey-color !important;
        resize: none;
        stroke: transparent;

        &::placeholder {
            color: transparent;
        }
    }

    .benefit-title-section, .table th {
        background: $athensgray-color !important;
        border-color: $athensgray-color !important;
    }

    label, sup, table td *, .input-box, .filter-notification, .table .input-box, .no-results, .question-comment, .question-number, .show-popup, .list-group-item img, .add-benefit img, .form-check, span img, .fields-timer svg {
        display: none;
    }

    .status, .activity-time, .past-days h4, .list-group-item, label, .info-section h1, .why-nations {
        &:before, &:after {
            display: none;
        }
    }

    img {
        filter: grayscale(100) contrast(0%) brightness(1.8);
        border-radius: 100%;
        width: 16px;
        height: 16px;
        min-width: 16px;
        min-height: 16px;
    }

    .sticky-nav {
        ul {
            border-left: 2px solid $athensgray-color;

            li {
                margin-left: 10px !important;

                img {
                    display: none;
                }
            }
        }
    }

    .breadcrumb {
        li {
            background: none !important;

            &:before {
                display: none;
            }
        }
    }

    .table {
        td, th {
            &:after {
                content: "";
                width: 100%;
                height: 28px;
                position: relative;
                display: inline-block;
                background: $athensgray-color !important;
                border-color: $athensgray-color !important;
            }
        }
    }

    .benefit-details {
        width: 100%;
        margin: 0;
    }


    .tabs-list, .sidebar {
        li, a {
            background: none !important;
            border: none !important;

            .nav-link {
                &:before, &:after {
                    display: none;
                }
            }
        }
    }

    .timeline, .timeline-box {
        .timeline-txt, .accordion-body, .timeline-content {
            p {
                * {
                    background: none !important;
                    border: none !important;
                }


                img {
                    display: none;
                }
            }
        }
    }

    .filter-section .dropdown-toggle, .form-floating, table {
        img {
            display: none;
        }
    }

    .info-section, footer, .why-nations {
        background: $white-color;
    }

    ul {
        padding: 0;

        li {
            min-height: 40px;
        }
    }
}

@keyframes loading-skeleton {
    from {
        opacity: 0.6;
    }

    to {
        opacity: 1;
    }
}