@import '../../scss/base.scss';

.notification-page {
    .page-content {
        .page-title-section {
            padding: 28px 48px;
            min-height: inherit;
            height: 90px;

            h3 {
                margin-bottom: 0;
            }
        }

        .page-content-bottom {
            height: calc(100vh - 90px);
        }
    }
}
.date-search-block {
    display: flex;

    .form-floating {
        margin-bottom: 24px;

        .form-control {
            height: 48px;
            font-size: 16px;
        }

        &.active-date-label label {
            transform: scale(0.80) translateY(-0.5rem) translateX(0.15rem);
        }
    }
}

.notifications-container {
    h5 {
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 0.1px;
        margin-bottom: 2px;
    }

    .sub-text {
        color: $nevada-color;
    }

    .notifications-flex {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        .notification-card {
            flex: 1 1 calc(272px - 24px); /* Adjust the percentage width as needed */
            max-width: 272px; /* Ensure consistent sizing for smaller screens */
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 4px;
            border: 1px solid #B1B9C1;
            border-radius: 4px;
            background-color: #fff;
            cursor: pointer;

            .icon {
                background: #F5F6F8;
                width: 100%;
                border-radius: 3px;
                text-align: center;
                padding: 17.5px;
            }

            span {
                font-size: 15px;
                line-height: 15px;
                color: #212121;
                font-weight: 500;
                padding: 12px 6px;
            }
        }
    }
}
