@import "base";

.accordion-button {
    box-shadow: none;
    outline: none;

    &:before, &:after {
        @include Transition2;
    }

    &:focus {
        box-shadow: none;
        outline: none;
    }

    &:not(.collapsed) {
        color: $woodsmoke-color;
        box-shadow: none;
        background: $athensgray-color;

        &::after {
            background-image: var(--bs-accordion-btn-icon);
        }
    }
}


.secondary-accordion {
    .accordion-button {
        &:not(.collapsed) {
            &::after {
                background-image: var(--cx-accordion-btn-active-icon);
                transform: var(--cx-accordion-btn-icon-transform)
            }

            &::before {
                background-image: var(--cx-accordion-btn-active-icon);
                transform: rotate(90deg);
                opacity: 0;
            }
        }

        &::after, &:before {
            content: '';
            background: $black-color;
            left: auto;
            position: absolute;
        }

        &::after {
            height: 2px;
            width: 16px;
            right: 21px;
        }

        ::before {
            height: 16px;
            width: 2px;
            right: 28px;
        }
    }
}

.leftarrow-accordion {
    .accordion-button {
        &:after {
            display: none;
        }

        &:before {
            content: "";
            margin-left: 0;
            width: 0;
            height: 0;
            border-left: 7px solid transparent;
            transform: rotate(-90deg);
            border-right: 7px solid transparent;
            border-top: 7px solid $nevada-color;
            margin: 0px 10px 0 0;
        }

        &:not(.collapsed) {
            &:before {
                transform: rotate(0deg);
            }
        }
    }
}