@import "base";

.sidebar {
    width: 72px;
    background: $primary-color !important;
    z-index: 999;
    text-align: center;
    border: none !important;
    @include Transition5;
    overflow: hidden;

    &:hover {
        width: 280px;

        .link-text, .portal-logo {
            opacity: 1;
            display: block;
        }

        .user-info {
            @include FlexCenterBetween;
            width: 100%;

            .username-truncate {
                max-width: 160px;
                display: block
            }
        }
    }

    .portal-logo {
        opacity: 0;
    }

    .logo {
        margin: 0 20px 24px;
        padding: 0;
        background: none !important;
        max-width: 170px;
    }

    a {
        padding: 15px 26px;
        @include FlexCenter;
        text-decoration: none;
        color: $athensgray-color;

        &:hover, &:focus, &.active {
            color: $athensgray-color;
            background: linear-gradient(98.05deg, $primary-color 7.74%, #0065a9 94.97%);
        }

        span {
            min-width: 18px;
            min-height: 18px;
            display: flex;

            &.link-text {
                font-size: 16px;
                line-height: 18px;
                margin: 0 0 0 16px;
                opacity: 0;
                display: none;
            }
        }
        /* .main-nav {
            img,
            svg {
                width: auto;
                max-width: 20px;
                height: 18px;
            }
        }*/
    }

    .user-details {
        position: relative;
        color: $white-color;
        font-size: 16px;
        line-height: 18px;

        .dropdown-item {
            background: none;

            &:hover {
                background: linear-gradient(98.05deg, $primary-color 7.74%, #0065a9 94.97%);
            }
        }

        &.show {
            .arrow {
                transform: rotate(-180deg);
            }
        }

        button {
            box-shadow: none !important;
            padding: 0;
            justify-content: start;
            background: $primary-color !important;
            border: none !important;
            margin: 0;
            width: 100%;
            position: relative;
            z-index: 1000;
            padding: 4px 24px 4px 16px;
        }

        .dropdown-menu {
            border: none !important;
            border-radius: 0;
            box-shadow: none;
            transform: translateY(0) !important;
            padding: 0;
            width: 100%;
            background: inherit;

            &.show {
                transform: translateY(-60px) !important;
            }
        }

        .user-circle {
            border: 1px solid $athensgray-color;
            min-width: 36px;
            min-height: 36px;
            margin: 0 10px 0 0;
            overflow: hidden;
            position: relative;
            @include FlexCenterCenter;
            color: $athensgray-color;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
        }

        .user-icon {
            min-width: 24px;
            justify-content: center;
        }
    }

    .user-info {
        color: $athensgray-color;
        font-weight: 400;
        display: none;

        span {
            text-align: left;
            line-height: 20px;

            &.role {
                font-size: 14px;
            }
        }

        p {
            margin: 0 0 5px;
        }
    }

    @include breakpoint (portrait-tablet-end) {
        max-width: 280px;
        z-index: 9999;

        .offcanvas-body {
            height: 100%;
            padding: 0;
        }

        .offcanvas-header {
            padding-top: 0;
            display: block;
            text-align: right;
            width: 100%;

            .btn-close {
                opacity: 1;
            }
        }

        .link-text, .portal-logo {
            opacity: 1 !important;
            display: block !important;
        }

        .user-info {
            opacity: 1 !important;
            width: 100%;
            @include FlexStart;
            justify-content: space-between;
        }
    }
}


.header {
    background: $primary-color !important;
    height: 48px;
    position: fixed;
    left: 0;
    width: 100%;
    @include FlexCenterCenter;
    padding: 8px 16px;
    z-index: 9;

    .menu-icon {
        position: absolute;
        left: 16px;
        top: 18px;
    }

    .logo {
        width: 24px;
        height: 24px;
    }
}


.modal-open {
    .page-content {
        filter: blur(2px);
    }
}
