@import '../../scss/base.scss';

.review-page {
    .page-content {
        .page-title-section {
            padding: 24px 48px;
            min-height: inherit;

            h3 {
                margin: 0;
            }
        }
    }

    table {
        td {
            .status {
                &:not(:last-child) {
                    margin: 0 20px 0 0;
                }
            }

            span {
                img {
                    margin: 0 6px 0 0
                }
            }
        }
    }
}


.preview-portal {
    position: relative;
    margin: 0 auto;
    text-align: center;

    &:before {
        background: rgba(16,50,66,0.2);
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    .preview {
        width: 240px;
        height: 140px;
        background: rgba($black-color, 0.5);
        position: absolute;
        left: 0;
        z-index: 999;
        top: 0;
        bottom: 0;
        right: 0;
        padding: 32px 88px;
        border-radius: 8px;
        margin: auto;
        @include FlexCenterCenter;
        flex-wrap: wrap;
        text-decoration: none;

        h5 {
            font-weight: 400;
            font-size: 18px;
            line-height: 22px;
            color: $white-color;
            margin: 6px 0 0;
        }
    }
}

.upload-catalog {
    h6 {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        margin: 0;
    }

    p {
        margin: 0 0 8px;
        color: $nevada-color
    }

    .uploaded-preview {
        @include FlexCenter;
    }

    .uploaded {
        background: $white-color;
        border: 1px solid $cadetblue-color;
        border-radius: 8px;
        @include FlexCenter;
        padding: 22px;
        min-width: 330px;
        font-weight: 600;
        color:$nevada-color;
        margin:0 28px 0 0;
    }
    a{
        text-decoration:none;
    }
}


.drag-drop {
    box-sizing: border-box;
    height: 180px;
    width: 325px;
    border: 1px dashed $cadetblue-color;
    border-radius: 8px;
    background-color: $white-color;
    padding: 53px 16px;
    cursor: pointer;
    text-align:center;

    .upload-text {
        @include FlexCenterCenter;
        flex-wrap:wrap;

        span {
            line-height: 16px;
            font-weight: 500;
            color:$nevada-color;

            &.small {
                color: $nevada-color;
                font-weight: normal;
                margin:6px 0 0;
                font-size:14px;
                line-height:17px;
            }
        }
    }

    input[type="file"] {
        visibility: hidden;
    }
}