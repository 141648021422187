@import "colors";
@import "breakpoints";

@mixin FlexCenter {
    display:flex;
    align-items:center;
}

@mixin FlexStart {
    display: flex;
    align-items: start;
}

@mixin FlexCenterCenter{
    display:flex;
    align-items:center;
    justify-content:center;
}

@mixin FlexCenterBetween{
    display:flex;
    align-items:center;
    justify-content:space-between;
}

@mixin TextTruncate {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 99%;
    white-space: nowrap;
}

@mixin Transition2 {
    transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
}

@mixin Transition5 {
    transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
}

@mixin ActionButton {
    background: $white-color;
    color: $woodsmoke-color;
    border-color: $cadetblue-color;
    border-radius: 4px;
}

@mixin OutlinePrimaryBtn {
    background: $white-color;
    border-color: $primary-color;
    color: $primary-color;
}

@mixin OutlineSecondaryBtn {
    background: $white-color;
    border-color: $secondary-color;
    color: $secondary-color;
}

@mixin BgWhigh-NoShadow {
    background-color: $white-color;
    box-shadow: none;
}
