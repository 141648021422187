@import '../../scss/base.scss';

.features-page {
    height: 100%;
    max-width: 1011px;
    min-height: 100%;
    overflow: auto;

    .title-features {
        margin-bottom: 24px;
        @include FlexCenter;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
    }

    .features-left {
        width: calc(100% - 150px);
    }

    .activity-section {
        min-width: 130px;
    }

    @include breakpoint (medium-desktop) {
        .features-right {
            padding-left: 24px;
        }

        h2 {
            font-size: 32px;
        }

        .title-features {
            @include FlexCenter;
            justify-content: space-between;
            flex-wrap: nowrap;
        }
    }

    h2 {
        margin-bottom: 0;
        font-weight: 600;
        color: $woodsmoke-color;
        line-height: 36px;
        font-size: 32px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    hr {
        border-color: $cadetblue-color;
        opacity: 0.5;
        margin: 1.5rem 0;
    }
}


.features-content {
    color: $woodsmoke-color;
    padding: 24px 16px 24px 24px;

    @include breakpoint (medium-desktop) {
        padding: 32px 16px 32px 32px;
    }

    .content-margin {
        margin: 0 0 24px;

        p {
            &:last-child {
                margin: 0;
            }
        }
    }

    h6 {
        color: $nevada-color;
        margin: 0 0 8px;
    }

    p {
        color: $woodsmoke-color;
    }

    .list-numaric {
        list-style: lower-alpha;
        margin: 16px 0;

        ul {
            list-style: disc;
            margin: 10px 0;
        }
    }

    .upload-section {
        padding: 24px;
        margin: 0 0 24px;

        .card-body {
            padding: 16px 0 0 16px;
        }

        .form-floating {
            margin: 24px 0 0;
        }

        .upload-info {
            @include FlexCenterBetween;
        }

        .uplad-actions {
            @include FlexCenterCenter;

            a {
                &:not(:last-child) {
                    img {
                        margin: 0 16px 0 0;
                    }
                }

                &.loading {
                    opacity: 0.5;
                }
            }
        }
    }
}

.nations-tag {
    background: $athensgray-color;
    border: 1px solid $primary-color;
    border-radius: 16px;
    padding: 3px 16px;
    font-size: 14px;
    line-height: 17px;
    color: $woodsmoke-color;
    font-weight: 600;
    margin-left: 12px;
}

.activity-action {
    color: $custom-link-color;
    font-weight: 500;
    margin: 0 20px 0 0;
}

.actions-button {
    min-width: inherit;
    min-height: inherit;
    border: none;
    padding: 0;
    margin: 0 !important;
    box-shadow: none !important;
}

/* Custom Slider Section SCSS */
.custom-slider {
    margin: 32px 0 0;
    max-width: 815px;

    &:hover {
        .carousel-control-prev, .carousel-control-next {
            img {
                opacity: 1;
            }
        }
    }

    img.slide-image {
        background: $light-grey-color;
        border: 1px solid $cadetblue-color;
        padding: 0 56px;
    }
    /* .carousel-item {
        img.slide-image {
            padding: 0 56px;
        }
    }*/


    .carousel-control-prev, .carousel-control-next {
        width: 56px;
        height: calc(100% - 31px);
        background: $light-grey-color;
        opacity: 1;
        border: 1px solid $cadetblue-color;

        img {
            opacity: 0;
            @include Transition5;
        }
    }

    .carousel-control-prev {
        border-right: none;
    }

    .carousel-control-next {
        border-left: none;
    }

    .carousel-caption {
        padding: 12px 0 0;
    }

    .carousel-caption, .carousel-caption h6 {
        position: initial;
        color: $woodsmoke-color;
        font-weight: 400;
        font-size: $font-size-basic;
        padding-bottom: 0;
        margin: 0;
    }

    .carousel-indicators {
        bottom: 30px;

        [data-bs-target] {
            background: $cadetblue-color;
            width: 8px;
            height: 8px;
            border-radius: 50px;
            display: inline-block;
            border: none;

            &.active {
                background: $primary-color;
            }
        }
    }
}

/* Sub Feature Name Section SCSS */
.form-floating {
    &.feature-head {
        label {
            font-weight: 600;
            font-size: 32px;
            color: $woodsmoke-color;
            padding: 1rem;
        }

        .form-control {
            font-size: 32px;
            height: 56px;

            &:focus, &:not(:placeholder-shown) {
                & ~ label {
                    color: $nevada-color;
                    font-weight: normal;
                }
            }
        }
    }
}


/* Add Sub Feature Section CSS */
.add-subfeature {
    .psudo-buttons {
        margin: 0 0 40px;

        p {
            margin: 0 0 12px;
        }
    }

    .upload-feature-img {
        .input-group {
            .btn-outline {
                border-radius: 4px !important;
            }
        }
    }
}


/* Old activity Screen UI SCSS */
/*.activity {
    position: fixed;
    right: 0;
    width: 640px;
    z-index: 9999;
    background: $white-color;
    height: calc(100% - 130px);
    padding: 0;
    box-shadow: 0px 4px 8px rgba(179, 188, 200, 0.5);
    top: 130px;
    border-left: 1px solid $cadetblue-color;
    .accordion-header {
        button {
            border: none !important;
            height: auto !important;

            .timeline-inner {
                margin: 0 !important;
            }
        }
    }

    .activity-head {
        background: $light-grey-color;
        border-bottom: 1px solid rgba($cadetblue-color, 0.4);
        padding: 16px 24px;
        height: 50px;
        @include FlexCenter;
        justify-content: space-between;

        h5 {
            margin: 0;
            font-size: 18px;
            line-height: 18px;
            color: $woodsmoke-color;
        }
    }

    .activity-body {
        padding: 15px;

        h6 {
            font-size: 16px;
        }

        .activity-list {
            margin: 1.5rem 0 0;
        }

        button {
            padding: 12px 16px;
            border-radius: 21px !important;
            height: 42px;
            min-width: inherit;
            font-weight: normal;
            min-height: inherit;
            border: 1px solid $cadetblue-color;
            background: $white-color;
            color: $woodsmoke-color;
            box-shadow: none !important;

            &:hover, &:focus {
                background: $white-color;
                border-color: $cadetblue-color;
                color: $woodsmoke-color;
            }

            &.active {
                background: $primary-color;
                border-color: $primary-color;
                color: $white-color;
            }
        }

        .timeline-box {
            border: 1px solid $athensgray-color;
            border-radius: 4px;
            background-color: var(--white-color);
            width: 100%;
            padding: 12px;

            a {
                margin-top: 1rem;
            }

            p {
                margin: 0;
                color: $woodsmoke-color;

                span {
                    color: $nevada-color;
                }
            }

            .timeline-inner {
                @include FlexCenter;
            }

            .timeline-icon {
                margin-right: 14px;

                span {
                    background: $athensgray-color;
                    padding: 15px;
                    @include FlexCenterCenter;
                    width: 48px;
                    height: 48px;
                    border-radius: 50%;
                }
            }

            .accordion-item {
                border: none;

                button, .accordion-body {
                    padding: 0;
                }

                .timeline {
                    list-style-type: none;
                    position: relative;
                    padding-left: 12px;
                    margin-top: 16px;

                    &:before {
                        content: ' ';
                        background: $cadetblue-color;
                        display: inline-block;
                        position: absolute;
                        left: 14px;
                        width: 1px;
                        height: 98%;
                        z-index: 400;
                    }

                    li {
                        margin: 0 0 32px;
                        padding-left: 26px;
                        position: relative;
                        z-index: 999;

                        &:before {
                            content: ' ';
                            background: $white-color;
                            display: inline-block;
                            position: absolute;
                            border-radius: 50%;
                            border: 1px solid $nevada-color;
                            left: -1px;
                            width: 8px;
                            height: 8px;
                            z-index: 400;
                            top: 5px;
                        }

                        &:after {
                            content: " ";
                            background: $white-color;
                            display: inline-block;
                            position: absolute;
                            border-radius: 50%;
                            left: -3px;
                            width: 14px;
                            height: 22px;
                            z-index: 398;
                            top: -2px;
                        }

                        &:last-child {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}*/
