@import '../../scss/base.scss';

.benefit-section {
    height: calc(100vh - 170px);
    overflow: auto;
    width: 100%;
}

.right-configurations {
    margin-left: 24px;

    p {
        font-size: 18px;
        line-height: 22px;
        margin: 0 0 4px;
        font-weight: 400;
    }
}

.question-main {
    padding: 4px 12px;
    background: $light-grey-color;

    &.feature-config-available {
        background: $purple-color;
    }

    &.disabled {
        pointer-events: none;
        opacity: 0.6;
    }
}

.show-feature-config {
    position: relative;
    max-width: 140px;

    button {
        padding-left: 50px !important;
    }

    .dropdown-menu {
        min-width: 100%;
    }

    .form-switch {
        position: absolute;
        left: 8px;
        top: 8px;
        z-index: 9;

        input {
            background-color: #646f7d73;
        }
    }
    .form-check-input {
        border: none;
        box-shadow: none;
        background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
        &:checked {
            background-color:$purple-color;
            border-color:$purple-color;
            box-shadow: none;
        }
    }
}

.question {
    background: $white-color;
    padding: 16px 24px 8px;
    border-radius: 4px;
    //margin: 0 0 4px;
    position: relative;
    border: 2px solid transparent;
    @include Transition5;
    z-index: 1;

    h6 {
        //@include FlexStart;
        // display:flow;
        letter-spacing: 0;
        margin: 0 0 10px;

        sup {
            top: 0;
            left: 4px;
            margin: 0 5px 0 0;
        }

        .show-popup {
            top: 5px;
        }
    }

    p {
        color: $nevada-color;
        margin: 0 0 8px;
        font-weight: 400;
    }

    .preview {
        margin: 14px 0 0;
    }

    &:hover {
        box-shadow: 0px 2px 4px rgba(100, 111, 125, 0.2);

        .question-number {
            color: $custom-link-color;
        }

        .question-comment {
            opacity: 1;
        }
    }

    &.active {
        border-color: #3EACF7;
    }

    .question-number {
        position: absolute;
        bottom: 8px;
        right: 16px;
        font-size: 14px;
        line-height: 17px;
        color: $nevada-color;
    }

    .question-comment {
        position: absolute;
        top: 8px;
        right: 16px;
        opacity: 0;
        @include Transition2;

        .edit-icon {
            cursor: pointer;
        }
    }

    .question-content {
        //white-space: nowrap;
        //overflow: auto;
        //padding-bottom:5px;
        //max-width:100%;

        &::-webkit-scrollbar {
            height: 8px;
            width: 8px;
            background: transparent;
        }

        &::-webkit-scrollbar-thumb:horizontal {
            background: $cadetblue-color;
            border-radius: 10px;
        }

        button {
            margin: 0 12px 12px 0;
        }
    }

    .form-floating {
        margin: 0 0 12px;
    }

    .question-actions {
        @include FlexCenter;

        a {
            margin: 0 0 0 12px;

            img {
                width: 16px;
            }
        }
    }
    .future-site {
        opacity: 0;
        cursor: pointer;
    }

    &:hover {
        .future-site {
            opacity: 1;
        }
    }

    .questions-inner {
        @include FlexCenterBetween;
    }
}

    .future-site-active {
        cursor: pointer;
        white-space:nowrap;
        margin: 0 0 0 40px;
        @include FlexCenterBetween;
        align-self: flex-start;

        .future-site-date {
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
            color: $nevada-color;
        }
    }



.benefit-title-section {
    @include FlexCenterBetween;
    padding: 16px 16px 16px 36px;
    background: $light-grey-color;
    border-bottom: 1px solid $cadetblue-color;
    top: 0;
    z-index: 9;

    .benefit-title-left,
    .benefit-title-right {
        @include FlexCenter;
    }

    h4 {
        font-size: 24px;
        line-height: 30px;
        margin: 0;
    }
}

.item-rule-question {
    display: flex;

    .form-floating {
        width: 100%;
    }

    span {
        margin: 16px 16px 0;
    }
}

.benefit-content-section {
    background: $light-grey-color;
    //padding: 4px 12px;
    padding-bottom: 4px;
    overflow: hidden;

    .add-benefit {
        min-height: 70px;
    }
}

.sticky-gap {
    padding-top: 68px;
}

.page-data {
    .page-input-group {
        margin: 0 0 12px;

        .input-box {
            display: block;

            &:not(:last-child) {
                margin: 0 0 12px;
            }

            .form-floating {
                margin: 0 0 0 26px;
                width: 360px;
            }
        }

        .form-check {
            label {
                margin: 0;
            }
        }
    }

    .inner-group {
        margin: 8px 0 12px 30px;
    }
}





.future-site-list {
    li {
        margin: 0;
        border-bottom: 1px solid $athensgray-color !important;
        margin: 0;

        &:last-child {
            border: none !important;
        }

        a {
            padding: 16px 24px;
            margin: 0;
            @include FlexStart;
            justify-content:flex-start;
            img{
                min-width:24px;;
            }

            .future-site-icon {
                width: 24px;
                height: 24px;
                margin: 0 8px 0 0;
                position: relative;
                @include Transition2;
            }

            .future-site-details {
                width: calc(100% - 60px);
                display: block;
            }

            .future-site-type {
                font-size: 18px;
                line-height: 24px;
                font-weight: 500;
                color: $woodsmoke-color;
                @include TextTruncate;
            }

            .future-site-duration {
                display: block;
                font-size: 16px;
                font-weight:400;
                line-height: 20px;
                color: $nevada-color;
            }
        }
    }
}