@import "base";

.tabs-list {
    align-items: self-end;
    border: none;
    height: 44px;
    margin: -44px 0 0;
    padding: 0 0 0 48px;

    li {
        margin: 0;

        .nav-link {
            padding: 8px 24px;
            background: #FAFAFA;
            border-radius: 8px 8px 0px 0px;
            margin: 0 1px;
            color: $nevada-color;
            border: none;
            position: relative;
            @include FlexCenter;
            //@include Transition5;
            isolation: auto !important;
            letter-spacing: 0;

            &:focus-visible {
                outline: none;
            }

            &:before, &:after {
                width: 6px;
                height: 8px;
                content: '';
                position: absolute;
                opacity: 0;
                //transition: all 0.3s ease 0.15s;
                bottom: 0;
            }
            /*&:last-child {
                &::before {
                    left: -8px;
                    border-right: 10px solid $white-color;
                    border-radius: 0 0 16px;
                    bottom: -3px;
                    transform: rotate(24deg);
                }

                &::after {
                    right: -8px;
                    border-left: 10px solid $white-color;
                    border-radius: 0 0 0 16px;
                    bottom: -3px;
                    transform: rotate(-24deg);
                }
            }*/

            &.active {
                padding: 10px 20px;
                color: $woodsmoke-color;
                font-weight: 700;
                z-index: 10;
                margin: 0 6px;

                &::before {
                    background: url(../images/curve-left.svg) no-repeat left top;
                    left: -6px;
                }

                &::after {
                    background: url(../images/curve-right.svg) no-repeat right top;
                    right: -6px;
                }

                &::after, &::before {
                    opacity: 1;
                }
            }
        }

        &:first-child {
            .nav-link {
                margin-left: 0;
            }
        }
    }

    @include breakpoint (medium-desktop) {
        padding: 0 0 0 48px;
    }
}

.tab-content, .page-content-bottom {
    background: $white-color;
    box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.05);
    border: none;
    position: relative;
    padding: 16px 24px 0;
    display: block;
    width: 100%;
    height: calc(100vh - 114px);
    overflow: hidden;
    /*&::-webkit-scrollbar {
        display: none;
    }*/

    @include breakpoint (medium-desktop) {
        padding: 24px 48px 0;
    }

    .tabs-container {
        display: block;
        width: 100%;
        height: 100%;
        max-height: calc(100vh - 130px);
        overflow: hidden;
    }
}

.tabslist-main {
    .tab-content {
        height: calc(100vh - 145px);
    }
}
.futuresite-page {
    .tab-content {
        height: calc(100vh - 64px);
    }
}
.main-content, .tab-pane, .main-content .row {
    height: 100%;
}

.main-content {
    & > .row {
        .col-3 {
            position: relative;
            /*padding: 16px 24px 16px 60px;
            height:100%;*/
            &:after {
                background: #E7EBF2;
                content: "";
                right: 0px;
                top: -16px;
                position: absolute;
                height: calc(100% + 16px);
                width: 2px;
            }
        }
    }
}


@include breakpoint (portrait-tablet-end) {
    .tab-content {
        padding: 0;
        box-shadow: none;
        border-top: 1px solid #ccc;
        height: calc(100vh - 96px) !important;

        tbody {
            tr {
                &:first-child {
                    td {
                        border-top: none;
                    }
                }
            }
        }

        .tabs-container {
            max-height: calc(100vh - 96px);
        }
    }

    .activity-buttons, .tabs-list {
        display: none !important;
    }
}
