$font-family:"proxima-nova";
$font-size-basic:16px;
$primary-color:#00497A;
$secondary-color:#F2604A;
$secondary-hover-color:#DF2C11;
$nevada-color:#646F7D;
$white-color:#FFF;
$black-color:#000;
$cadetblue-color:#B1B9C1;
$custom-link-color:#255798;
$woodsmoke-color:#212121;
$neutralblue-color:#ECFAFF;
$info-color: #33BDE3;
$athensgray-color:#E9ECF0;
$error-color:#D61308;
$success-color:#0FA91C;
$error-border-color:#FEE9E8;
$success-border-color:#ECF3ED;
$light-green:#F3FAFF;
$light-grey-color:#f5f6f8;
$form-error-border: #dc3545;
$purple-color: #9D51FF;