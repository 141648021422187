@mixin breakpoint($responsive) {
    @if $responsive == large-desktop {
        @media (max-width: 1920px) {
            @content;
        }
    }

    @else if $responsive == medium-desktop {
        @media (min-width: 1600px) {
            @content;
        }
    }

    @else if $responsive == small-desktop {
        @media (min-width: 1440px) {
            @content;
        }
    }

    @else if $responsive == desktop-tablet-middle {
        @media (max-width: 1199px) {
            @content;
        }
    }

    @else if $responsive == tablet-landscape-end {
        @media (max-width: 1024px) {
            @content;
        }
    }

    @else if $responsive == tablet-landscape-start {
        @media (min-width: 992px) {
            @content;
        }
    }

    @else if $responsive == portrait-tablet-end {
        @media (max-width: 991px) {
            @content;
        }
    }
    @else if $responsive == portrait-tablet-only {
        @media (min-width:768px) and (max-width: 991px) {
            @content;
        }
    }
    @else if $responsive == portrait-tablet-start {
        @media (max-width: 768px) {
            @content;
        }
    }

    @else if $responsive == mobile {
        @media (max-width: 767px) {
            @content;
        }
    }

    @else {
        @warn "Breakpoints will be supported: large-desktop, medium-desktop, small-desktop, desktop-tablet-middle, tablet-landscape-end, tablet-landscape-start, portrait-tablet-end, portrait-tablet-start, mobile";
    }
}
