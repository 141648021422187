@import "base";

.status {
    padding: 7px 12px 8px 24px;
    background: transparent;
    border: 1px solid transparent;
    color: $woodsmoke-color;
    position: relative;
    border-radius: 8px;
    height: 34px;
    font-size: 18px !important;
    line-height: 18px !important;
    display: inline-block;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    white-space: nowrap;

    &:before {
        width: 8px;
        height: 8px;
        border-radius: 100%;
        display: inline-block;
        position: absolute;
        content: '';
        left: 8px;
        top: 12px;
        background-color: transparent;
    }

    &.new {
        background: $light-grey-color;
        border-color: $cadetblue-color;

        &:before {
            background: $nevada-color;
        }
    }

    &.draft {
        background: $neutralblue-color;
        border-color: $info-color;

        &:before {
            background: $info-color;
        }
    }
    &.progress{
        min-width:126px;
    }

    &.review, &.progress {
        background: #FDE5CA;
        border-color: #FF8800;

        &:before {
            background: #FF8800;
        }
    }

    &.approved, &.published {
        background: $success-border-color;
        border-color: $success-color;

        &:before {
            background: $success-color;
        }
    }

    &.failed {
        background: $error-border-color;
        border-color: $error-color;

        &:before {
            background: $error-color;
        }
    }

    @include breakpoint (portrait-tablet-end) {
        padding: 5px 12px 5px 20px;
        height: 28px;

        &:before {
            top: 10px;
        }
    }
}


.action {
    padding: 2px 8px;
    border-radius: 4px;
    font-weight: 600;

    &.action-grey {
        background: $light-grey-color;
        font-weight: normal;
    }

    &.action-green {
        background: $success-border-color;
        color: $woodsmoke-color;
    }

    &.action-orange {
        background: #FDE5CA;
    }
}
