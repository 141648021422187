@import "base";

.show-popup {
    position: relative;
    @include FlexCenterCenter;
    display: inline-flex;
    box-shadow: none !important;

    .hover-popup {
        z-index:10;
        box-shadow: 0px 4px 16px rgba($black-color, 0.2);
        position: absolute;
        background: #2E404D;
        min-width: 86px;
        padding: 12px 16px;
        color: $white-color;
        text-align: center;
        border: 0px;
        border-radius: 8px;
        left: auto;
        right: auto;
        white-space: nowrap;
        width: auto;
        display: none;
        font-size: 14px;
        line-height: 16px;
        font-weight: 600;

        &::after, &::before {
            border: solid transparent;
            content: "";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-width: 8px;
            margin-left: -8px;
        }

        &::before {
            border-bottom-color: #2E404D;
        }

        &.top {
            bottom: 100%;
            margin-bottom: 10px;
            -webkit-animation: fade-in 0.5s linear 1, move-up 0.5s linear 1;
            -moz-animation: fade-in 0.5s linear 1, move-up 0.5s linear 1;
            -ms-animation: fade-in 0.5s linear 1, move-up 0.5s linear 1;

            &::after, &::before {
                top: 100%;
                left: 50%;
                transform: rotate(180deg);
            }
        }

        &.bottom {
            top: 100%;
            margin-top: 10px;
            -webkit-animation: fade-in 0.5s linear 1, move-down 0.5s linear 1;
            -moz-animation: fade-in 0.5s linear 1, move-down 0.5s linear 1;
            -ms-animation: fade-in 0.5s linear 1, move-down 0.5s linear 1;

            &::after, &::before {
                bottom: 100%;
                left: 50%;
            }
        }

        &.right {
            margin-left: 10px;
            left: 100%;
            -webkit-animation: fade-in 0.5s linear 1, move-right 0.5s linear 1;
            -moz-animation: fade-in 0.5s linear 1, move-right 0.5s linear 1;
            -ms-animation: fade-in 0.5s linear 1, move-right 0.5s linear 1;

            &::after, &::before {
                top: 30%;
                right: 100%;
                transform: rotate(-90deg);
            }
        }

        &.left {
            margin-right: 10px;
            right: 100%;
            -webkit-animation: fade-in 0.5s linear 1, move-left 0.5s linear 1;
            -moz-animation: fade-in 0.5s linear 1, move-left 0.5s linear 1;
            -ms-animation: fade-in 0.5s linear 1, move-left 0.5s linear 1;

            &::after, &::before {
                top: 30%;
                right: -8%;
                transform: rotate(90deg);
            }
        }

        &.white-popup {
            background: $white-color;
            border: 1px solid $cadetblue-color;
            width: 230px;
            color: $primary-color;
            font-size: 14px;
            letter-spacing: 0;
            line-height: 18px;
            font-weight: 500;
            white-space: pre-wrap;
            text-align: left;

            &::after {
                border-color: rgb(255 255 255 / 0%);
                border-bottom-color: $white-color;
            }

            &::before {
                border-color: rgba(25,76,97,0);
                border-bottom-color: $secondary-color;
                border-width: 9px;
                margin-left: -9px;
            }

            &:hover {
                .hover-popup {
                    &.bottom {
                        width: 178px;
                        background-color: $white-color;
                        box-shadow: 0 4px 16px 0 rgb(0 0 0 / 12%);
                        color: $primary-color;
                        white-space: normal;
                        padding: 8px;
                        font-size: 16px;
                        font-weight: 600;
                        letter-spacing: 0.3px;
                        line-height: 20px;

                        &::after {
                            border-bottom-color: $white-color;
                        }
                    }
                }
            }
        }
    }

    &:hover {
        .hover-popup {
            display: block;
        }
    }
}

@-webkit-keyframes fade-in{from{opacity:0;}to{opacity:1;}}
@-webkit-keyframes move-up{from{bottom:25px;}}
@-webkit-keyframes move-down{from{top:25px;}}
@-webkit-keyframes move-right{from{left:25px;}}
@-webkit-keyframes move-left{from{right:25px;}}