@import '../../scss/base.scss';

.access-denied-sec {
    background: $primary-color;
    height: 100vh;

    .access-denied-inner {
        @include FlexCenterCenter;
        flex-direction: column;
        height: 100%;
        width: 100%;
    }

    .logo {
        margin: 0 0 45px;

        img {
            height:90px;
        }
    }

    .access-denied-box {
        width: 96%;
        max-width: 400px;
        padding: 32px;
        background: $white-color;
        border-radius: 4px;

        h2 {
            font-size: 32px;
            line-height: 40px;
            margin: 0 0 32px
        }

        p {
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            margin: 0 0 24px
        }

        button {
            width: 100%
        }
    }
}
