@import "../../scss/base.scss";

.sticky-nav {
    min-width: 300px;
    width: 300px;
    margin: 24px 0 0 0;
    max-height: 80Vh;
    //overflow: auto;
    padding-left: 3px;
    display:inline-flex;
    align-items: center;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        border-left: 1px solid $cadetblue-color;

        li {
            position: relative;
            margin: 0 0 0 -4px;
            border-left: 7px solid transparent;
            padding: 4px 0 6px 12px;
            font-weight: 500;
            font-size: 20px;
            color: $custom-link-color;
            text-decoration: underline;
            @include FlexCenter;

            &:not(:last-child) {
                padding: 5px 0 5px 12px;
                margin: 0 0 6px -4px;
            }

            &.active {
                border-color: $secondary-color;
                color: $secondary-color;
            }

            /*a {
                font-weight: 500;
                font-size: 20px;
                line-height: 24px;
                padding: 6px 0 14px;
                color: $custom-link-color;
                display: inline-block;
                &::before {
                    background: transparent;
                    width: 7px;
                    position: absolute;
                    left: -19px;
                    content: "";
                    height: 100%;
                    top: 50%;
                    transform: translateY(-50%);
                }

                &.active {
                    color: #d64029;

                    &::before {
                        background: $secondary-color;
                    }
                }
            }
            &.active {
                border-color: $secondary-color;

                a {
                    color: $secondary-color;
                }
            }*/
        }
    }
}
.fields-timer{
    color:$nevada-color;
    margin:24px 0 0 30px;
    display:flex;
}
