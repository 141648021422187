@import '../../scss/base.scss';


/* Features List Tabs Section CSS */
.features-list {
    width: 100%;
    flex-wrap: wrap;
    height: calc(100vh - 115px);
    @include FlexStart;

    .features-tabs {
        li {
            .nav-link {
                &.active {
                    background: $light-green;
                    color: $woodsmoke-color;
                    /*
                    &::after, &::before {
                        opacity: 1;
                        border-color: $light-green;
                    }*/
                }
            }
        }
    }

    .tab-content {
        background: $light-green;
        padding: 16px 0 0 24px;
        //height:calc(100vh - 117px);
        height: 100%;

        .tabs-container {
            display: flex;
        }

        & > div {
            height: 100%;
        }
    }
}

/* Features Search Section CSS */
.features-search {
    margin: 0 0 24px 24px;
    max-width: 275px;

    .form-control {
        height: 48px;
        background-color: $white-color;
        border: none;
        border-bottom: 2px solid $cadetblue-color;
        font-size: 16px;
        color: $nevada-color;
        line-height: 16px;
        padding-right: 40px !important;

        &:focus {
            border-color: $primary-color;
        }
    }

    img, svg {
        position: absolute;
        top: 50%;
        right: 16px;
        z-index: 1;
        cursor: pointer;
        transform: translateY(-50%)
    }
}


/*Accordion Section CSS */
.features-accordion {
    position: relative;
    height: 100%;
    padding: 0;
    overflow: hidden;

    .feature-align {
        @include FlexStart;

        span {
            margin: 0 5px 0 0;
        }
    }

    .scroll-accordion {
        height: calc(100% - 60px);
        overflow: auto;
    }

    .features-search {

        .form-control {
            width: 272px;
            height: 40px;
        }
    }

    .rc-scrollbars-view {
        overflow-x: hidden !important;
        padding-bottom: 70px;

        @include breakpoint (large-desktop) {
            padding: 0;
        }

        @media(max-height:768px) {
            max-height: 575px !important;
        }
    }

    ul li a.active, ul.active.head-list > li > a.active, .inner-submenu-active-title-active button {
        width: calc(100% + 24px);
        background: linear-gradient(154deg, #03568E 0%, #0065A9 100%) !important;
        border-radius: 0 !important;
    }

    .accordion-body {
        padding: 16px 0 0;
    }

    .accordion-header {
        width: calc(100% - 24px);
        display: inline-block;
    }

    .accordion-item {
        background: none;
        border: none;
        padding: 0 0 24px 0;
        width: 100%;
        display: inline-block;
    }

    .accordion-button {
        padding: 8px 20px;
        min-height: 40px;
        border-radius: 0;
        background: none;
        color: $woodsmoke-color;
        box-shadow: none;
        width: 100%;
        @include FlexCenter;
        justify-content: space-between;

        &::after {
            display: none;
        }

        .accordion-icon {
            width: 24px;
            height: 24px;
            min-width: 24px;
            min-height: 24px;
            border: 2px solid $nevada-color;
            border-radius: 100%;
            display: inline-block;
            position: relative;
            margin: 0 12px 0 0;

            &::after, &::before {
                content: '';
                background: $nevada-color;
                left: auto;
                position: absolute;
                border-radius: 4px;
            }

            &::after {
                height: 2px;
                width: 14px;
                right: 3px;
                top: 9px;
            }

            &::before {
                height: 14px;
                width: 2px;
                right: 9px;
                top: 3px;
            }
        }

        &:not(.collapsed) {
            .accordion-icon {
                &::before {
                    background-image: var(--cx-accordion-btn-active-icon);
                    transform: rotate(90deg);
                    opacity: 0;
                }

                &::after {
                    background-image: var(--cx-accordion-btn-active-icon);
                    transform: var(--cx-accordion-btn-icon-transform);
                }
            }
        }
    }

    .features {
        @include FlexCenter;
        line-height: 28px;
    }

    .inner-list {
        .accordion-header {
            width: 338px;
        }

        .accordion-body {
            padding: 0 0 10px 32px;
            width: calc(100% - 48px);
            background: #EDF7FF;
            margin: 0 0 8px;
        }

        .inner-submenu-active-title {
            button {
                span {
                    font-size: 18px;
                    line-height: 24px;
                    color: $woodsmoke-color !important;
                    font-weight: 600;

                    @include breakpoint (medium-desktop) {
                        font-size: 20px;
                    }
                }

                &:not(.collapsed) {
                    background: #D9EBF8;
                    border-radius: 6px 6px 0 0;
                    min-height: 40px;
                    color: $nevada-color;
                    width: calc(100% - 48px);
                    font-size: 18px;
                    line-height: 24px;

                    &:before {
                        display: none;
                    }
                }
            }
        }


        .accordion-button {
            //min-height: 40px;
            color: $nevada-color;

            &.collapsed {
                width: calc(100% - 48px);

                &:hover {
                    background-color: #D9EBF8;
                    border-radius: 6px;
                }
            }

            span {
                position: relative;
                z-index: 9;
                color: $nevada-color;
            }
        }

        .inner-menu {
            .accordion-button {
                span {
                    color: $nevada-color;
                }
            }
        }

        .accordion-item {
            padding: 0 0 8px;

            &:last-child {
                padding: 0;
            }
        }

        a {
            &.nav-link {
                display: block;
                padding: 8px 16px;
                color: $woodsmoke-color;
                text-decoration: none;
                width: 100%;
                position: relative;
                border-radius: 6px;
                /*&.active {
                    width: calc(100% + 24px);
                    padding-right: 0px;
                }*/

                &:hover {
                    background-color: #D9EBF8;
                }
            }

            &.custom-link {
                text-decoration: underline;
                color: $custom-link-color;
            }
        }

        .accordion-button, .accordion-collapse .active, .active-accordion button.collapsed {
            span {
                color: $white-color;
                z-index: 9;
                position: relative;
            }


            &:before {
                //background: linear-gradient(147.97deg, #03568E 25.2%, #0065A9 82.17%) !important;
                background: linear-gradient(154deg, #03568E 0%, #0065A9 100%) !important;
                font-weight: 600;
                font-size: 18px;
                border-radius: 0;
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 338px;
                z-index: 1;
                height: 100%;
            }

            &.collapsed {
                color: $nevada-color;

                span {
                    position: relative;
                    z-index: 9;
                    color: $nevada-color;
                }

                &:before {
                    background: transparent;
                }
            }
        }

        .accordion-collapse .active {
            &:before {
                left: -32px;
            }
        }

        .active-accordion button {
            width: 100% !important;

            span {
                color: $white-color !important;
            }
        }

        .inner-menu {
            .accordion-button {
                color: $nevada-color;

                &:before {
                    display: none;
                }
            }
        }
    }


    .inner-submenu-active, .inner-submenu {
        .accordion-button {
            span {
                color: $nevada-color;
            }

            &:before {
                background: none !important;
            }
        }

        .collapsed {
            span {
                color: $nevada-color;
            }

            &:before {
                background: none !important;
            }
        }
    }
    /*.inner-submenu {

        .accordion-collapse {
            display: none !important;
        }
    }*/
}

.add-category {
    background: $light-green;
    padding: 15px 48px 30px;
    border-top: 1px solid $athensgray-color;
    position: absolute;
    width: 100%;
    left: -24px;
    bottom: 0;
    z-index: 9;
}

@include breakpoint (medium-desktop) {
    .inner-list .accordion-button:not(.collapsed), .inner-list .accordion-collapse .active, .inner-list .accordion-button.active {
        font-size: 20px;
        line-height: 24px;
        color: $white-color;
        position: relative;
        z-index: 9;
    }
}
