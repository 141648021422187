@import "base";

/* Dropdown CSS */
.dropdown-menu {
    top: 100%;
    text-align: left;
    position: absolute;
    z-index: -1;
    border-radius: 8px;
    display: block;
    opacity: 0;
    -webkit-transform: translateY(100px) !important;
    -moz-transform: translateY(100px) !important;
    -ms-transform: translateY(100px) !important;
    -o-transform: translateY(100px) !important;
    transform: translateY(100px) !important;
    -webkit-transition: all 0.4s cubic-bezier(0.18, 0.89, 0.32, 1.28);
    -moz-transition: all 0.4s cubic-bezier(0.18, 0.89, 0.32, 1.28);
    -ms-transition: all 0.4s cubic-bezier(0.18, 0.89, 0.32, 1.28);
    -o-transition: all 0.4s cubic-bezier(0.18, 0.89, 0.32, 1.28);
    transition: all 0.4s cubic-bezier(0.18, 0.89, 0.32, 1.28);
    border: 0 !important;
    box-shadow: 0 4px 16px 1px rgba(0,0,0,0.15);
    left: 0;
    overflow: hidden;
    padding: 0;

    &.show {
        opacity: 1;
        -webkit-transform: translateY(48px) !important;
        -moz-transform: translateY(48px) !important;
        -ms-transform: translateY(48px) !important;
        -o-transform: translateY(48px) !important;
        transform: translateY(48px) !important;
        z-index: 999;
    }

    a, .dropdown-item-text {
        padding: 9px 16px;
        font-weight: normal;
        color: $woodsmoke-color;
        outline: none;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        @include FlexCenterBetween;
        cursor: pointer;
        width: 100%;
        /*&:not(:last-child) {
            border-bottom: 1px solid $athensgray-color;
        }*/

        span {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;

            &.status {
                margin: 0 0 0 36px;
                font-size: 12px;
                line-height: 15px;
                padding: 4px;
                height: auto;
                border-radius: 4px;

                &:before {
                    display: none;
                }
            }
        }

        img {
            margin: 0 8px 0 0;
        }

        &:hover, &:focus, &.active {
            background: $neutralblue-color;
            color: $woodsmoke-color;
        }

        .input-box {          
            .form-check, .form-check-input, label {
                cursor: pointer;
            }
            span {
                max-width: 90%;
            }
        }
    }
    .dropdown-item-text {
        cursor: default;
    }
}

.dropdown-toggle {
    &:after {
        display: none !important;
    }

    .arrow {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        transition: -webkit-transform .6s;
        transition: transform .6s;
        transition: transform .6s,-webkit-transform .6s;
        margin: 0 0 0 8px;
        right: 12px;
    }

    &.show {
        .arrow {
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg)
        }
    }
}
