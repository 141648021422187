@import '../../scss/base.scss';

footer {
    background: $primary-color;
    color: $white-color;
    @include FlexStart;
    justify-content: space-between;
    padding: 20px 24px;
    position: relative;

    .footer-right {
        min-width: 215px;
    }

    .footer-left-inner {
        @include FlexStart;
    }

    p {
        font-size: 16px;
    }

    .operations {
        margin: 0 48px 0 0;

        p {
            span {
                display: block;
                font-weight: bold;
            }
        }

        .questions {
            padding-bottom: 16px;
            border-bottom: 1px solid $secondary-color;
            margin-bottom: 16px;
            display: inline-block;

            h5 {
                font-family: "museo-slab";
                font-weight: 400;
                font-size: 20px;
                line-height: 20px;
                margin-bottom: 8px;
            }

            P, a {
                font-family: "museo-slab";
                font-weight: 300;
                font-size: 16px;
                line-height: 20px;
                margin: 0;
            }
        }

        .social-media {
            flex-direction: row;

            a {
                width: 28px;
                height: 28px;
                background: none;
                padding: 0;
                border: none;
                display: inline-block;
                margin: 0 8px 0 0;

                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }
    /* Footer Links SCSS */
    .footer-links-inner {
        @include FlexStart;
    }

    h5 {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        padding-bottom: 6px;
        margin: 0 0 16px;

        &:after {
            position: absolute;
            left: 0;
            bottom: 0;
            background: $secondary-color;
            height: 2px;
            width: 34px;
            content: '';
        }
    }

    .links-list {
        @include FlexStart;
        align-items: start
    }

    .features {
        margin: 0 48px 0 0;
    }

    .list-group {
        max-width: 195px;
        margin: 0 32px 0 0;

        .list-group-item {
            padding: 0;
            background: none;
            border: none;
            color: $white-color;
            font-size: 16px;
            margin: 0 0 24px;

            &:hover {
                opacity: 0.7;
            }
        }

        &:last-child {
            margin: 0;
        }
    }
    /* inquiries Section SCSS */
    .inquiries {
        p {
            font-size: 16px;
            margin: 0;
            font-weight: 300;
        }

        h6 {
            color: $white-color;
            font-size: 16px;
            line-height: 22px;
            font-weight: normal;

            a, u {
                font-weight: 700;
                font-size: 16px;
            }
        }
    }

    .footer-logos {
        @include FlexCenter;
        margin: 0 0 30px;
    }

    .copyrights {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        margin: 0;
    }


    @include breakpoint (medium-desktop) {
        padding: 40px 48px;

        .operations {
            margin: 0 48px 0 0;
        }

        .footer-links {
            .features {
                margin: 0 64px 0 0;
            }
        }
    }

    @include breakpoint (desktop-tablet-middle) {
        .links-list {
            display: block;
        }

        .features {
            margin: 0 24px 0 0;
        }
    }

    @include breakpoint (portrait-tablet-only) {
        .footer-right {
            position: absolute;
            right: 24px;
            top: 24px;
        }
    }

    @include breakpoint (portrait-tablet-end) {
        display: block;

        .footer-left-inner {
            display: block;
        }

        .operations {
            margin: 0 0 24px;
        }

        .features {
            margin: 0;
        }
    }

    @include breakpoint (mobile) {
        .footer-links-inner {
            display: block;
        }

        .links-list {
            display: block;
        }

        .list-group {
            .list-group-item {
                margin: 0 0 16px;
            }
        }
    }
}
