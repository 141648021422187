@import "base";

.table-radius {
    border: 1px solid $athensgray-color;
    border-radius: 8px;
    //border-bottom: none;
    background: $white-color;

    th, td {

        &:first-child {
            border-left: none;
        }

        &:last-child {
            border-right: none;
        }
    }

    td {
        border: 1px solid $athensgray-color;
        color: $woodsmoke-color;
    }

    @include breakpoint (portrait-tablet-end) {
        border-radius: 0;
        //border:none;
        border-color: #ccc;
    }
}

.table {
    margin-bottom: 0;
    position: relative;
    z-index: auto;
    display: table;
    table-layout: fixed;
    width: 100%;



    & > :not(caption) > * > * {
        padding: 0;
    }

    thead {
        background: #F5F5F5;
        /*tr {
            display: flex;
        }*/

        th {
            font-weight: 500;
            color: $nevada-color;
            background: #F5F5F5;
            cursor: inherit;
            white-space: nowrap;
            position: sticky;
            z-index: 5;
            top: 0;
            line-height: 18px;
            /*&.action{
                width:116px;
                min-width:116px;
                max-width:116px;
            }*/
        }
    }

    th, td {
        border-left: 1px solid $athensgray-color;
        padding: 7px 16px;
        vertical-align: middle;
        font-size: 16px;
        line-height: 17px;
        position: relative;
        cursor: pointer;
        width: 300px;

        &.carriers {
            min-width: 640px;
            width: 640px;
            max-width: 640px;
        }

        &.plans-info {
            min-width: 530px;
            max-width: 530px;
            width: 530px;
        }

        &.plans, &.benefit-year {
            min-width: 150px;
            max-width: 150px;
            width: 150px;
        }

        &.created {
            min-width: 250px;
            max-width: 250px;
            width: 250px;
        }

        &.status-section {
            min-width: 200px;
            max-width: 200px;
            width: 200px;
        }

        &.benefits-count, &.action {
            min-width: 110px;
            max-width: 110px;
            width: 110px;
        }

        &.updated {
            width: 420px;
            min-width: 420px;
            max-width: 420px;

            span {
                display: block;
            }
        }

        &.benefit-type, &.direct-benefits {
            min-width: 190px;
            max-width: 190px;
            width: 190px;
        }

        .action-list {
            @include FlexCenter;

            img {
                &:not(:last-child) {
                    margin: 0 12px 0 0;
                }
            }
        }
    }

    th {
        &:after {
            content: '';
            position: absolute;
            top: auto;
            right: 0;
            height: 1px;
            border-bottom: 1px solid $athensgray-color;
            bottom: 0;
            width: 100%;
        }
    }

    &.scrollbar-table {
        thead {
            width: calc(100% - 6px);
            position: relative;

            th {
                &:last-child:after {
                    border-bottom: 1px solid $athensgray-color;
                    content: '';
                    position: absolute;
                    width: 6px;
                    height: 41px;
                    right: -6px;
                    top: 0;
                }
            }
        }

        thead, tbody tr {
            display: table;
            table-layout: fixed;
            width: 100%;
        }

        tbody {
            display: block;
            max-height: calc(100vh - 114px);
            overflow-y: scroll;

            &::-webkit-scrollbar {
                width: 6px;
                right: 20px;
            }

            &::-webkit-scrollbar-thumb {
                background: $nevada-color;
                border-radius: 8px;
            }
        }
    }

    &.table-hover {
        tr {
            &:hover, &.active {
                td {
                    background: $neutralblue-color;
                    --bs-table-accent-bg: $neutralblue-color;
                }
            }

            &.no-hover {
                &:hover {
                    td {
                        background: none;
                        cursor: initial;
                    }
                }
            }
        }
    }

    tr:last-child {
        td {
            border-bottom: none;
            /*padding-bottom: 25px;*/
        }
    }
    /*&.plans-table {
        th {
            line-height: 28px;
        }
    }*/
    /*@include breakpoint (portrait-tablet-end) {
        .rc-scrollbars-view, .rc-scrollbars-container {
            padding-bottom: 20px;
            max-height: calc(100vh - 130px) !important;
            tr:last-child {
                td {
                    padding-bottom: 20px;
                }
            }
        }
    }*/
    /*&.plans-table{
        td{
            cursor:default;
        }
    }*/

    @include breakpoint (tablet-landscape-start) {
        .rc-scrollbars-view, .rc-scrollbars-container {
            //padding-bottom: 20px;
            max-height: calc(100vh - 280px) !important;
            /*tr:last-child {
                td {
                    padding-bottom: 20px;
                }
            }*/
        }

        &.plans-table {
            .rc-scrollbars-view, .rc-scrollbars-container {
                max-height: calc(100vh - 280px) !important;
            }
        }
    }

    @include breakpoint (portrait-tablet-end) {
        .rc-scrollbars-view, .rc-scrollbars-container {
            padding-bottom: 0;
            max-height: calc(100vh - 140px) !important;
        }

        th {
            &:after {
                border-color: #ccc;
            }
        }

        &.plans-table {
            th {
                line-height: inherit;
            }

            tr {
                &:hover {
                    td {
                        background: none !important;
                    }
                }
            }
        }
    }

    th, td {
        @media(max-width:1600px) {
            &.carriers, &.updated, &.plans-info {
                min-width: 370px;
                width: 370px;
                max-width: 370px;
            }
        }

        @media(min-width:1360px) and (max-width:1366px) {
            &.carriers, &.updated, &.plans-info {
                min-width: 342px;
                width: 342px;
                max-width: 342px;
            }

            &.created, &.status-section {
                min-width: 160px;
                max-width: 160px;
                width: 160px;
            }
        }

        @include breakpoint (portrait-tablet-end) {
            font-size: 14px;
            line-height: 17px;
            border-color: #ccc;
            padding: 10px 16px;
            min-height: 40px;
            height: 40px;
            max-height: inherit;
            /*border-right: 1px solid #ccc;
            border-left:none;*/
            span {
                font-size: 14px;
                line-height: 17px;
            }

            &.benefit-year {
                min-width: 110px;
                width: 110px;
                max-width: 110px;
                border-right: none;
            }

            &.plans, &.created, &.carrier-status, &.updated, &.carrier-action, &.benefits-count, &.benefit-type, &.direct-benefits {
                display: none;
            }
            /*&.carriers {
                width: calc(100vw - 112px);
                max-width: inherit;
                min-width: calc(100vw - 112px);
            }*/

            &.carriers {
                width: calc(100vw - 112px);
                max-width: inherit;
                min-width: calc(100vw - 112px);

                .form-check {
                    display: none;
                }
            }
            /*&.plans-info {
                width: calc(100vw - 212px);
                max-width: inherit;
                min-width: calc(100vw - 212px);

                .form-check {
                    display: none;
                }
            }*/


            &.status-section {
                width: 120px;
                max-width: 120px;
                min-width: 120px;

                .status {
                    font-size: 14px !important;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    max-width: 100%;
                    width: auto;
                }
            }

            &.action {
                width: 60px;
                max-width: 60px;
                min-width: 60px;
                border-radius: 0;
            }
        }


        @include breakpoint (mobile) {
            &.action {
                max-width: 50px;
                min-width: 50px;
                padding: 8px 0 8px 16px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }


        @media(max-width:500px) {
            &.plans-info {
                width: calc(100vw - 205px);
                min-width: calc(100vw - 205px);
            }
        }
    }

    th {
        @include breakpoint (portrait-tablet-end) {
            padding: 8px 16px;
            min-height: inherit;
            height: auto;
        }
    }
}

@-moz-document url-prefix() {
    .scrollbar-table thead {
        width: 100%;
    }
}
/*.plans-table{
    td{
        pointer-events:none;
    }
}*/
/*.plans-table {
    th {
        line-height: 28px;
    }

    @include breakpoint (medium-desktop) {

        th, td {
            &.plans-info, &.updated {
                min-width: 350px;
                width: 350px;
                max-width: 350px;
            }
        }
    }
}*/






/* 
.table-scroll {
    height: calc(100vh - 200px);

    table {
        &.flex-table {
            display: flex;
            flex-direction: column;
            height: 100%;
            tbody {
                flex: 1;
                overflow-y: auto;

                &::-webkit-scrollbar {
                    width: 8px;
                    height: 8px;
                    right: 20px;
                }

                &::-webkit-scrollbar-thumb {
                    background: $nevada-color;
                    border-radius: 8px;
                }
            }
        }
    }
}*/


/*@include breakpoint (tablet-landscape-start) {
    .rc-scrollbars-view, .rc-scrollbars-container {
        max-height: calc(100vh - 250px) !important;

        table {
            margin-bottom: 20px;
        }
    }
}*/

.rc-scrollbars-thumb-v {
    margin: 30px 0 0;
}

.table-responsive {
    .rc-scrollbars-view, .rc-scrollbars-container {
        @include breakpoint (portrait-tablet-end) {
            max-height: calc(100vh - 100px) !important;
        }

        @include breakpoint (tablet-landscape-start) {
            max-height: calc(100vh - 235px) !important;
        }
    }
}

.expandable-table {
    .rc-scrollbars-view, .rc-scrollbars-container {
        @include breakpoint (portrait-tablet-end) {
            max-height: calc(100vh - 200px) !important;
        }

        @include breakpoint (tablet-landscape-start) {
            max-height: calc(100vh - 310px) !important;
        }
    }

    th, td {
        font-size: 15px;
    }

    .accordion-button {
        padding: 0;

        &:after {
            display: none;
        }
    }

    .w-470 {
        width: 470px;
        min-width: 470px;
        max-width: 470px;
    }

    .w-512 {
        width: 512px;
        min-width: 512px;
        max-width: 512px;
    }

    .w-84 {
        width: 84px;
        min-width: 84px;
        max-width: 84px;
    }

    .w-130 {
        width: 130px;
        min-width: 130px;
        max-width: 130px;
    }

    .w-175 {
        width: 175px;
        min-width: 175px;
        max-width: 175px;
    }

    .w-220 {
        width: 220px;
        min-width: 220px;
        max-width: 220px;
    }

    .w-242 {
        width: 242px;
        min-width: 242px;
        max-width: 242px;
    }

    .w-248 {
        width: 248px;
        min-width: 248px;
        max-width: 248px;
    }

    .accordion-item {
        border: 0;
    }

    .date-field {
        .form-control {
            background: $light-grey-color;
            border: 0;
            border-radius: 0;
            padding: 12px 10px;
            font-size: 16px;

            &:focus {
                outline: none;
                box-shadow: none;
                background: $light-grey-color;
            }
        }
    }

    .accordion-header {
        .accordion-button {
            th {
                background-color: #ECFAFF;
                /*&.carrier-text{
                    position: relative;
                    &::before{
                        content: "";
                        position: absolute;
                        background: url(../images/down-arrow.svg) no-repeat;
                        width: 24px;
                        height: 24px;
                        left: 16px;
                        top:12px;
                        transition: transform 0.3s ease;
                    }
                }*/
            }

            &.collapsed {
                th {
                    background-color: $white-color;
                    /*&.carrier-text{
                        position: relative;
                        &::before{
                            content: "";
                            position: absolute;
                            background: url(../images/down-arrow.svg) no-repeat;
                            width: 24px;
                            height: 24px;
                            left: 16px;
                            top:12px;
                            transform: rotate(-90deg);
                        }
                    }*/
                }
            }
        }

        table {
            border: 0;

            th {
                background: $white-color;
                border-bottom: 0;
                font-weight: 400;
                color: $woodsmoke-color;

                .input-box {
                    justify-content: center;

                    .form-check {
                        margin-left: 7px;
                    }
                }
            }

            td {
                padding: 8px 16px;

                .input-box {
                    justify-content: center;
                }
            }
        }
    }

    .accordion-body {
        padding: 0;
        border-bottom: 4px solid $athensgray-color;

        table {
            .plans-text {
                td {
                    color: $nevada-color;
                    text-transform: uppercase;
                    font-size: 13px;
                    line-height: 16px;
                    letter-spacing: 0.3px;
                    font-weight: 500;
                    border-bottom: 3px solid $athensgray-color;
                    border-top: 0;
                }
            }

            td {
                border-bottom: 1px solid $athensgray-color;

                .input-box {
                    justify-content: center;
                }
            }
        }
    }

    .action-btns {
        .btn {
            padding: 0;
            min-width: 52px;
            min-height: 32px;
            border-radius: 4px;
        }

        .btn-secondary {
            background: #D64029;
            border-color: #D64029;

            &:hover {
                background: $secondary-color;
                border-color: $secondary-color;
            }
        }
    }

    .custom-link {
        font-size: 15px;
        text-decoration: none;
    }

    .revoke-icon {
        width: 16px;
        margin-right: 8px;
    }

    .status {
        font-size: 15px !important;
        line-height: 18px !important;
        padding: 5.5px 11px 5.5px 23px !important;
        height: 31px;

        &:before {
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .input-space {
        padding: 4px 6px;
    }

    .time-block {
        position: relative;

        .form-control {
            height: 40px;
            border-radius: 0;
            border: 0;
            padding: 12px 10px;
            background: $light-grey-color;
            font-size: 15px;
            line-height: 18px;

            &:focus {
                box-shadow: none;
            }

            &::placeholder {
                font-size: 13px;
                line-height: 16px;
                color: $nevada-color;
            }
        }

        .time-zone {
            position: absolute;
            top: 16px;
            right: 12px;
        }

        .btn {
            background: none;
            border: 0;
            min-width: inherit;
            min-height: inherit;
            color: $nevada-color;
            padding: 0;
            font-size: 13px;
            line-height: 16px;
            letter-spacing: 0.3px;
            margin-right: 6px;

            &.active {
                color: #D64029;
                box-shadow: none;
            }
        }
    }

    &.carrier-sm-table {
        max-width: 766px;

        tbody {
            td, th {
                padding-top: 14px;
                padding-bottom: 14px;
            }
        }
    }

    .input-box {
        justify-content: center;
    }
}

.features-search {
    .form-control {
        background-color: $light-grey-color !important;
    }
}
