@import '../../scss/base.scss';

.why-nations {
    min-height: 415px;
    background: $white-color;
    /*background-image: linear-gradient(to top bottom, rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(../../images/why-nations-bg.svg);*/
    padding: 70px 50px;
    position: relative;
    z-index: 1;

    .row {
        z-index: 9;
        position: relative;
        @include FlexCenterBetween
    }

    h2 {
        margin: 0 0 20px;
    }

    &:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: url(../../images/why-nations-bg.svg) no-repeat left top;
        z-index: 1;
    }

    .nations-img {
        img {
            border-radius: 8px;
        }
    }

    .why-nations-content {
        text-align: center;

        ul {
            border: none;
            border-radius: 0;
            justify-content: center;

            li {
                border: none;
                border-radius: 0 !important;
                position: relative;
                padding: 0;
                min-width: 170px;
                background: none;
                margin: 0;

                &:not(:last-child) {
                    padding: 0 20px;

                    &:before {
                        height: 2px;
                        content: '';
                        width: 100%;
                        position: absolute;
                        top: 50px;
                        left: 125px;
                        background: $secondary-color;
                        z-index: 1;
                    }
                }

                .img-section {
                    border: 1px solid $primary-color;
                    border-radius: 64px;
                    background: $white-color;
                    display: inline-block;
                    padding: 30px;
                    margin: 0 0 18px;
                    z-index: 9;
                    position: relative;
                    width: 100px;
                    height: 100px;
                }

                p {
                    margin: 0;
                    color: $woodsmoke-color;
                }
            }
        }
    }

    @include breakpoint (medium-desktop) {
        min-height: 505px;

        .why-nations-content {
            ul {
                li {
                    min-width: 210px;

                    &:not(:last-child) {
                        padding: 0 30px;
                    }
                }
            }
        }
    }

    @media(max-width:1300px) {
        h2 {
            margin: 0 0 12px;
        }

        .nations-img {
            text-align: center;
            margin: 0 0 24px;
        }

        .why-nations-content {
            ul {
                li {
                    min-width: 155px;

                    &:not(:last-child) {
                        padding: 0 10px;
                    }
                }
            }
        }
    }

    @include breakpoint (mobile) {
        padding: 32px;
        min-height: inherit;

        .why-nations-content {

            ul {
                display: inline-block;
                text-align: left;

                li {
                    @include FlexCenter;
                    padding: 20px 0 0;

                    &:not(:last-child) {
                        padding: 20px 0;

                        &:before {
                            width: 2px;
                            height: 100%;
                            bottom: 0;
                            left: 24px;
                        }
                    }

                    .img-section {
                        margin: 0 15px 0 0;
                        width: 48px;
                        height: 48px;
                        padding: 12px;
                    }
                }
            }
        }
    }
}
