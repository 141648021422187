@import '../../scss/base.scss';

.add-catagory-section {
    height: 100%;
    @include FlexCenterCenter;
    display:none
}

.add-catagory-btn {
    border: 1px dashed $nevada-color;
    border-radius: 100px;
    width: 200px;
    height: 200px;
    display: inline-block;
    font-weight: 100;
    font-size: 138px;
    line-height: 168px;
    color: $cadetblue-color;
    text-decoration: none;
    @include FlexCenterCenter;

    &:hover {
        color: $cadetblue-color;
    }
}

.form-content {
    color: $nevada-color;
    margin: 0 0 10px;
}

.modal-footer {
    button {
        font-weight: 700;
    }
}