@import '../../scss/base.scss';

.configuration-page {
    @include FlexStart;
}

.input-box {
    @include FlexCenter;

    .form-check {
        margin: 0;
        //height: 20px;
        min-height: 20px;
        min-width: 20px;
        //width: 20px;

        input {
            margin-top: 0;
        }
    }
}
/* Rounded buttons CSS */
.activity-buttons {
    margin: 0;
    @include FlexCenter;

    & > button {
        border-radius: 21px !important;
        min-width: inherit !important;
        /*&.active {
            background: $primary-color !important;
            border-color: $primary-color !important;
            color: $white-color !important;
        }*/
    }

    .dropdown-arrow {
        .dropdown-menu {
            min-width: 100%;
            width: 170px;
        }

        a {
            @include FlexCenterBetween;
            font-size:14px;
            border-bottom:1px solid $athensgray-color;
            &:last-hild{
                border:none;
            }

            .status {
                margin: 0;
                padding: 0 0 0 16px;
                font-size:14px !important;
                background:none;
                border:none;

                &:before {
                    width: 8px;
                    height: 8px;
                    border-radius: 100%;
                    display: inline-block;
                    position: absolute;
                    content: "";
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }
}

.filter {
    color: $primary-color;
    font-weight: 500;
    text-decoration: none;
}

/* Page top Section CSS */
.page-title-section {
    .submit {
        font-weight: 700;
    }

    h3 {
        font-size: 28px;
        line-height: 34px;
        color: $woodsmoke-color;
        letter-spacing: 0.3px;

        @include breakpoint (tablet-landscape-start) {
            & > span {
                font-size: 28px;
                line-height: 34px;
            }
        }
    }

    .page-top-left {
        h3 {
            position: relative;
            @include FlexCenter;

            img {
                position: absolute;
                left: -24px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}

.page-top-actions {
    .btn {
        &.btn-outline-primary {
            border-color: $cadetblue-color;
            padding: 4px;
            min-width: inherit;
            min-height: 44px;
            min-width: 46px;
            flex-direction: column;
            margin: 0 !important;

            * {
                font-size: 10px;
                line-height: 10px;
            }
        }
    }
}
.search-download {
    @include FlexCenter;
    .download{
        min-width:inherit;
        border-color:$cadetblue-color;
        width:48px;
        height:48px;
        padding:4px;
    }
}

.search-download {
    @include FlexCenter;

    .download {
        min-width: inherit;
        border-color: $cadetblue-color;
        width: 48px;
        height: 48px;
        padding: 4px;
    }
}

/* Tables CSS */
.table-actions {
    @include FlexCenterBetween;
    margin: 0 0 12px;
    z-index: 9;
    position: sticky;
    width: 100%;
}

.table-search {
    max-width: 350px;
    width: 350px;
    margin: 0;
    /*position: fixed;*/
    right: 0;

    .form-control {
        background: $light-grey-color;
    }

    label {
        padding: 13px;
    }
}

/* List Ietms CSS */
.benefit-status {
    margin: 0 0 8px;

    .list-group-item {
        padding: 0 10px 0 0;
        margin: 0 8px 0 0;
        border: 0;
        @include FlexCenter;

        &:first-child {
            margin-top: 2px;
        }

        &:after {
            height: 14px;
            background: $nevada-color;
            width: 1px;
            position: absolute;
            right: 0;
            top: 5px;
            content: '';
        }

        &:last-child {
            &:after {
                display: none;
            }
        }
    }
}


/* Filters Section CSS */
.filter-section {
    .form-field {
        margin: 0;
    }

    .dropdown-toggle {
        margin: 0 0 16px;
        padding: 0;
        width: 100%;
        color: $custom-link-color;
    }

    .filter-buttons {
        @include FlexStart;
        margin: 10px 0 0;

        a {
            width: auto;
        }
    }

    .filter-dropdown {
        min-width: 350px;
        padding: 16px;
        background: $white-color;
        box-shadow: 0px 4px 16px 1px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        overflow: inherit !important;
        z-index: -1;
        visibility: hidden;

        &.show {
            visibility: visible;
        }

        .dropdown {
            button {
                padding: 0;
                border: none;

                &:hover, &:focus, &:visited {
                    box-shadow: none;
                    border: none;
                }
            }
        }

        .arrow {
            position: absolute;
            right: 12px;
            top: 12px;
        }

        .dropdown-menu {
            width: 320px;
        }

        .form-control {
            cursor: pointer;
            height: 48px;
            caret-color: transparent;
            padding-right: 30px;
        }

        #filterStatus {
            & > label {
                padding: 12px 16px;
                font-weight: normal;
            }
        }
    }
}

.filters {
    padding: 0;
    border: none;
    color: $woodsmoke-color;
    font-weight: 500;
    min-height: 40px;
    margin: 0 !important;
    min-width: inherit;

    &:hover, &:focus {
        box-shadow: none;
    }
}

.filter-section {
    margin: 0 24px 0 0;
}

.filter-result {
    margin: 0;
    color: $nevada-color;
}

.filter-notification {
    background-color: $error-color;
    height: 8px;
    left: 12px;
    top: 7px;
    width: 8px;
    border-radius: 100%;
    position: absolute;
}


.benefits {
    @include FlexCenter;
    width:calc(100% + 24px);

    .benefit-icon {
        &:not(:last-child) {
            margin-right: 12px;
        }
    }
}

/* Content Controls Section SCSS */
.content-controls {
    @include FlexCenter;
    flex-wrap: wrap;
    padding: 16px 16px 20px;
    background: $light-grey-color;
    border-radius: 8px;
    max-width: 272px;
    margin-left: auto;

    h5 {
        font-size: 18px;
        margin: 0 0 10px;
        line-height: 24px;
    }

    p {
        font-size: 18px;
        margin: 0 0 10px;
        line-height: 24px;
        color: $woodsmoke-color;
    }

    a {
        margin: 28px 0 0;
    }
}


/* Launch Wizard Modal CSS */
.modal-body {
    .semect-member {
        margin: 0 0 36px;
    }

    h6 {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin: 0 0 8px;

        &.date {
            margin: 0 0 2px;
        }

        sup {
            top: 0;
        }
    }

    .react-datepicker-wrapper {
        width: 100%;
    }

    .section-outline {
        padding: 12px 16px;
        margin: 0 0 8px;
        border: 1px solid $cadetblue-color;
        border-radius: 4px;
        font-weight: 600;
        display: flex;
        cursor:pointer;

        .form-check {
            margin: 0;
        }

        &.active {
            border-color: $secondary-color;
            background: rgba($secondary-color, 0.1)
        }

        label {
            align-self: center;
        }
    }

    label {
        text-align: left;

        span {
            font-size: 16px;
            line-height: 20px;
            font-weight: 400;
            color: $nevada-color;
        }
    }
}


@include breakpoint (portrait-tablet-end) {
    .features-search, .table-search {
        width: 100%;
        max-width: 100%;
        margin: 0 0 8px;
    }

    .table-actions {
        margin: 0;
    }
}

/* CSS for the disabled state */
.disabled-div {
     pointer-events: none; /* Non-interactive */
  }
  
  /* CSS for the enabled state */
  .enabled-div {
    pointer-events: auto; /* Re-enable interactivity */
  }
  
