@import "base";

.btn {
    @include FlexCenterCenter;
    display: inline-flex;
    min-width: 95px;
    min-height: 48px;
    border-radius: 0.5rem;
    margin: 0;
    padding: 9px 24px;
    line-height: 28px;
    font-weight: 700;

    &:not(:last-child) {
        margin-right: 16px;
    }

    &.btn-sm {
        font-size: $font-size-basic;
        line-height: $font-size-basic;
        //padding: 0.4rem 1.2rem;
        padding: 8px 16px;
        min-width: inherit;
        min-height: 36px;
    }
    &.btn-md {
        min-width:140px;
    }

    &:hover, &:focus, &.active {
        box-shadow: 0 4px 14px 0 rgb(0 0 0 / 10%);
    }

    &.disabled, &:disabled {
        background: $cadetblue-color !important;
        border-color: $cadetblue-color !important;
    }


    &.btn-primary {
        background: $primary-color;
        border-color: $primary-color;

        &:hover, &.active, &:focus {
            background: $primary-color;
            border-color: $primary-color;
        }
    }

    &.btn-secondary {
        background: $secondary-color;
        color: $white-color;
        border-color: $secondary-color;

        &:hover, &.active, &:focus {
            color: $white-color;
            background: $secondary-hover-color;
            border-color: $secondary-hover-color;
        }
    }


    &.btn-outline {
        @include ActionButton;
        min-height: 40px;
        border-radius: 4px;
        padding: 9px 16px;
        font-weight: 500;
        line-height: 20px;
        min-width: 75px;

        &:hover, &.active, &:focus {
            @include ActionButton;
            box-shadow: none;
        }

        &.active {
            background: rgba($secondary-color, 0.1) !important;
            border-color: $secondary-color !important;
        }

        &.active.disabled, &.active:disabled {
            background: rgba(130,67,58,0.1) !important;
            border-color: #BD4A39 !important;
        }

        &.disabled, &:disabled {
            background: $light-grey-color !important;
            /*border-color: $primary-color;*/
            color: $nevada-color;
            opacity: 1;
        }
    }

    &.btn-outline-primary {
        @include OutlinePrimaryBtn;

        &:hover, &.active, &:focus {
            @include OutlinePrimaryBtn;
        }

        &.disabled, &:disabled {
            background: transparent !important;
            border-color: $primary-color;
            color: $nevada-color;
        }
    }

    &.btn-outline-secondary {
        @include OutlineSecondaryBtn;

        &:hover, &.active, &:focus {
            @include OutlineSecondaryBtn;
        }

        &.disabled, &:disabled {
            background: transparent;
            border-color: $secondary-color;
        }
    }

    @include breakpoint (mobile) {
        min-height: 40px;
        padding: 4px 16px;
    }
}
