@import '../../scss/base.scss';

.our-features {
    padding: 40px 0 56px;
    background: $light-grey-color;
    overflow: hidden;

    h2 {
        font-weight: 700;
        font-size: 32px;
        line-height: 36px;
        color: $primary-color;
        margin-bottom: 24px;
        text-align: center;
    }

    .row {
        justify-content: center;
    }

    .col {
        min-width: 250px;
        width: 250px;
        max-width: 250px;
    }

    .card {
        box-shadow: 0px 1px 8px rgba(0, 36, 84, 0.1);
        border-radius: 8px;
        border: none;
        background: $white-color;
        overflow: hidden;
        height: 256px;
        @include Transition2;
        cursor: pointer;

        &:hover {
            box-shadow: 0px 1px 28px rgba(0, 36, 84, 0.1);
        }

        .card-title {
            @include FlexCenterBetween;
            align-items: flex-start;

            h3 {
                font-size: 22px;
                line-height: 28px;
                color: $primary-color;
                margin: 0 48px 0 0;
            }

            img {
                min-width: 16px;
                min-height: 16px;
                position: absolute;
                right: 14px;
            }
        }

        p {
            color: $nevada-color;
            margin: 0 0 8px;
        }

        .card-img {
            background: rgba($athensgray-color,0.4);
            padding: 40px;
            width: 157px;
            height: 157px;
            @include FlexCenterCenter;
            border-radius: 100%;
            //margin:0 -50px -55px auto
            position: absolute;
            right: -35px;
            bottom: -35px
        }
    }

    @include breakpoint (medium-desktop) {
        .row {
            margin: 0 -16px
        }

        .col {
            max-width: 295px;
            padding: 0 16px;
        }
    }

    @media(max-width:1350px) {
        padding: 40px 20px;
    }

    @include breakpoint (mobile) {
        padding: 40px 16px;

        .row {
            justify-content: flex-start;
        }

        .col {
            min-width: 50%;
            width: 50%;
            max-width: 50%;

            .card {
                height: 200px;

                .card-title {
                    h3 {
                        font-size: 16px;
                        line-height: 20px;
                    }

                    img {
                        right: 10px;
                    }
                }

                .card-img {
                    padding: 32px;
                    width: 100px;
                    height: 100px;
                    right: -16px;
                    bottom: -16px;
                }
            }
        }
    }
}
