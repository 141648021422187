@import '../../scss/base.scss';

.footer-button {
    background: $white-color;
    box-shadow: 0px -4px 16px rgba(59, 66, 88, 0.1);
    padding: 16px 48px;
    position: fixed;
    left: 72px;
    bottom: 0;
    width: 100%;
}

.choose-benefit {
    .btn.btn-outline {
        width: 100%;
        justify-content: start;
        //margin-bottom: 16px;
        padding: 12px 16px;
        height: 100%;

        .form-check-input {
            /*margin-top: 0;*/
        }

        span {
            width: 20px;
            margin: 0 10px 0 8px;

            &.button-text {
                width: auto;
                margin: 0 0 0 4px;
            }
        }

        &.disabled, &:disabled {
            .form-check-input {
                background-color: $athensgray-color;

                &:checked {
                    background-color: rgba($cadetblue-color, 0.5);
                    border-color: rgba($cadetblue-color, 0.1);
                }
            }
        }
    }

    .rc-scrollbars-view {
        padding: 0 16px 30px 0;

        @include breakpoint (portrait-tablet-start) {
            padding: 0 16px 110px 0;
        }
    }

    p {
        color: $nevada-color;
    }

    &.plan-benefit {
        .btn.btn-outline, .add-benefit {
            padding: 15px 24px;
            height: 56px;

            span {
                margin-left: 0;
            }
        }
    }
}

.carrier-details {
    h5 {
        margin: 0 0 24px;
        line-height: 29px;
    }

    .carrier-info {
        @include FlexStart;
        color: $woodsmoke-color;
    }

    .dropdown button {
        padding: 0;
        border: none;
        cursor: pointer;
    }

    .dropdown-menu {
        min-width: 100%;
    }

    .carrier-contact-setion{
        margin-top:16px;
    }
}
