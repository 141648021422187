:root {
    --scrollbar-width-height: 15px; //desired width of the scrollbar
}

@import "base";

html {
    scroll-behavior: smooth;
}

.loading {
    background: url(../images/loader.gif) no-repeat 50% hsla(0,0%,100%,.8);
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99999;
}

.row {
    margin: 0 -12px;

    [class*="col-"], .col {
        padding: 0 12px;
    }
}

.hide-scroll {
    &::-webkit-scrollbar {
        display: none;
    }
}

* {
    box-sizing: border-box;
}

*, body {
    margin: 0;
    padding: 0;
    font-family: $font-family;
    /*letter-spacing: 0.3px;*/
    font-size: $font-size-basic;
    line-height: 24px;

    @include breakpoint (medium-desktop) {
        font-size: 18px;
    }

    @include breakpoint (mobile) {
        font-size: 14px;
        line-height: 18px;
    }
}

*, *:before, *:after {
    box-sizing: border-box;
}

a, button {
    @include Transition2;
}

.custom-link {
    color: $custom-link-color;
    padding: 0;
    min-width: inherit;
    margin: 0px;
    display: inline-block;
    cursor: pointer;
    font-weight: 500;

    &:hover {
        text-decoration: none;
        color: $custom-link-color;
    }
}

.nobr, .nobr a {
    text-decoration: none
}

.cursor-pointer {
    cursor: pointer;
}

.rc-scrollbars-track {
    min-width: 8px;
}

/*.rc-scrollbars-track-h {
    display: none !important;
}*/
.rc-scrollbars-view {
    overflow: auto !important;
    min-height: inherit !important;
    margin: 0 !important;

    &::-webkit-scrollbar {
        display: none;
    }
}
/*.horizontal-scroll {
    .rc-scrollbars-track-h {
        display: block !important;
    }
}*/

.rc-scrollbars-thumb {
    background: $cadetblue-color !important;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
    font-weight: 700;
    position: relative;
    letter-spacing: 0.3px;
}

h6 {
    font-size: 18px;
    font-weight: normal;
    color: $woodsmoke-color;
    margin: 0 0 10px;
}


/* Sub Feature Section SCSS */
.add-benefit {
    border: 1px dashed $cadetblue-color;
    border-radius: 8px;
    padding: 8px 16px;
    @include FlexCenterCenter;
    font-weight: 500;
    min-height: 48px;
    background: $white-color;
    margin: 0 0 4px;

    svg {
        margin: 0 10px 0 0;
    }
}

.add-config {
    border: 1px solid $primary-color;
    border-radius: 8px;
    padding: 11px 16px;
    @include FlexCenterCenter;
    font-weight: 700;
    font-size:18px;
    line-height:18px;
    background: $white-color;
    color:$primary-color;

    img {
        margin: 0 10px 0 0;
        width:10px;
    }
}

/* Vedio Button Section SCSS */
.vedio-button {
    background: $neutralblue-color;
    padding: 6px 14px;
    color: $woodsmoke-color;
    border: 1px solid $cadetblue-color;
    text-decoration: none;
    border-radius: 6px;
    height: 40px;

    &:hover {
        color: $woodsmoke-color;
        opacity: 0.7;
    }
}

/* Features Table Section SCSS */
.feature-table {
    border-radius: 6px;

    table {
        background: $light-grey-color;
        text-align: center;
        border-color: $white-color;
        border-spacing: 0;
        border-collapse: separate;

        thead {
            th {
                padding: 4px 12px;
                border-color: $cadetblue-color;
                background: $primary-color;
                color: $white-color;
                font-weight: 700;

                &:first-child {
                    border-top-left-radius: 6px;
                    border-left: 1px solid $cadetblue-color;
                }

                &:last-child {
                    border-top-right-radius: 6px;
                    border-right: 1px solid $cadetblue-color;
                }
            }
        }

        tr {
            &:last-child {
                td {
                    &:first-child {
                        border-bottom-left-radius: 8px;
                    }

                    &:last-child {
                        border-bottom-right-radius: 8px;
                    }
                }
            }
        }

        td {
            padding: 8px 8px 12px;
            border-color: $cadetblue-color;
        }
    }

    @include breakpoint (medium-desktop) {
        table {
            th {
                padding: 5px 20px;
            }
        }
    }
}

/* List Items SCSS */
ol, ul {
    margin-bottom: 1rem;
    padding: 0 0 0 1.5rem;

    li {
        margin: 0 0 10px;

        a {
            color: $custom-link-color;
        }
    }
}


.no-results {
    text-align: center;
    padding: 50px 0;

    h6 {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: $black-color;
        margin: 0;
    }

    p {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $nevada-color;
        margin: 6px 0 0;
    }
}

.page-disabled {
    opacity: 0.4;
    pointer-events: none;
}