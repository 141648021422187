@import "base";

.form-field, .form-floating {
    position: relative;
    margin-bottom: 32px;

    .form-field-icon, .form-status-icon {
        right: 16px;
        /*width: 24px;
        height: 24px;*/
        transform: translateY(-50%);
        cursor: pointer;
        top: 50%;
        position: absolute;
    }

    &.active-date-label {
        .form-control {
            padding-top: 1.625rem;
            padding-bottom: 0.625rem;
        }

        label {
            transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
            opacity: 0.65;
        }
    }

    label {
        @include Transition2;
        font-size: 16px;
        line-height: 20px;
        color: $nevada-color;
        padding: 16px;
        width: calc(100% - 22px);
    }

    .form-control:focus ~ label, .form-control:not(:placeholder-shown) ~ label, .select-field label.form-label {
        //background: $light-grey-color;
        height: auto;
        //padding: 0.25rem 1rem 0;
        //opacity: 1;
        //transform: scale(1) translateY(0rem) translateX(0rem);
        font-size: $font-size-basic;
    }

    .form-control, .form-select {
        height: 56px;
        border: none;
        border-bottom: 2px solid $cadetblue-color;
        border-radius: 4px;
        background-color: $light-grey-color;
        padding: 4px 16px;
        box-shadow: none;
        width: 100%;
        outline: none;
        color: $woodsmoke-color;
        text-overflow: ellipsis;


        &:focus {
            border-color: $custom-link-color;
            border-width: 2px;
            color: $woodsmoke-color;
        }

        &:disabled, &.disable {
            background-color: $athensgray-color;
            pointer-events: none;
            color: $nevada-color;
        }
    }

    .form-control {
        &:read-only, &.readonly:read-only {
            background-color: $athensgray-color;
            pointer-events: none;
            color: $nevada-color;
        }
    }

    .form-select {
        line-height: 3.5;

        &.readonly:read-only {
            background-color: $athensgray-color;
            pointer-events: none;
            color: $nevada-color;
        }
    }
    // .form-control:hover, .form-control:focus, .form-select:hover, .form-control:focus, .form-select:focus {
    //     //background: $light-grey-color;
    //     color: $woodsmoke-color;
    // }


    .form-success {
        .form-select {
            &:hover, &:focus {
                @include BgWhigh-NoShadow;
            }
        }
    }

    label {
        &.form-label {
            position: absolute;
            pointer-events: none;
            left: 18px;
            transform: translateY(-47%);
            top: 47%;
            color: $nevada-color;
            line-height: 20px;
            padding: 0;
            margin: 0;
            height: auto;
            width: auto;
            transition: 0.2s ease all;
            -moz-transition: 0.2s ease all;
            -webkit-transition: 0.2s ease all;
            border: none;
        }
    }

    input[type="date"] {
        &::-webkit-inner-spin-button, &::-webkit-calendar-picker-indicator {
            -webkit-appearance: none;
            opacity: 1;
            width: 24px;
            height: 24px;
            position: absolute;
            right: 10px;
            z-index: 99;
            cursor: pointer;
            opacity: 0;
        }
    }

    textarea {
        &.form-control {
            height: auto;
            min-height: 90px;
            padding: 1.5rem 1rem !important;
        }
    }

    .textarea-field {
        label {
            &.form-label {
                top: 16px;
            }
        }
    }

    &.form-error {
        .form-control, .form-select, .form-control:focus, .form-select:focus {
            border: 1px solid $form-error-border;
            background-color: $white-color;
        }
    }

    &.form-success {
        .form-control, .form-select, .form-control:focus, .form-select:focus {
            border: 1px solid $success-color;
            background-color: $white-color;
        }
    }

    &.icon-field {
        .form-status-icon {
            cursor: inherit;
            right: 40px;
        }
    }

    .invalid-feedback, .valid-feedback {
        position: absolute;
    }

    @include breakpoint (portrait-tablet-end) {
        .form-control, .form-select {
            height: 48px;
        }

        label {
            padding: 12px 16px;
        }
    }
}

.required {
    label::after {
        content: " *";
        color: $secondary-color;
    }
}

sup {
    color: $secondary-color;
    font-size: 16px;
    line-height: 16px;
    display: inline-block;
}

.form-check {
    margin: 0 0 10px;

    .form-check-input {
        //height: 20px;
        //width: 20px;
        border: 1px solid $cadetblue-color;
        background-color: $neutralblue-color;
        box-shadow: none;
        min-width:20px;
        min-height:20px;

        &:checked {
            background-color: $secondary-color;
            border-color: $secondary-color;
        }

        &:checked[type="radio"] {
            background-size: 26px;
        }
    }

    label {
        color: $nevada-color;
        line-height: 28px;
        margin-left: 4px;
    }

    &.form-switch {
        .form-check-input {
            width: 32px;
            height: 16px;
        }
    }
}

.form-check-inline {
    .form-check {
        display: inline-block;
        margin-right: 16px;
    }

    .form-check-input {
        &:focus {
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
        }

        &:checked {
            &:focus {
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
            }
        }
    }
}


.search-input {
    padding-right:50px !important;
    &::-webkit-search-cancel-button {
        font-size: 0px;
        cursor: pointer;
        right: 16px;
        position: absolute;
        background: url(../images/close.svg) no-repeat right center;
        width: 16px;
        height: 16px;
        background-size: 16px;
        -webkit-appearance: none;
        top: 15px;
    }
}

.react-datepicker-wrapper {
    width: 100%;
}

.Selected {
    label {
        transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
        height: auto;
        opacity: 1;
        font-size: 16px;
    }

    .form-control {
        padding-top: 1.2rem;
    }
}


/* Date Picker SCSS */
.react-datepicker__header{
    select{
        border:1px solid $nevada-color;
        padding:4px;
        border-radius:4px;
        outline:none !important;
    }
}

.react-datepicker__current-month {
    font-size: 18px !important;
    margin:0 0 10px;
}

.react-datepicker__day--outside-month {
    color: $athensgray-color !important;
    background: $white-color !important;
}

.react-datepicker__day {
    padding: 4px;
    border-radius: 50% !important;
    outline: none;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    height: 32px !important;
    line-height: 18px !important;
    width: 32px !important;
    font-size:16px;
    @include FlexCenterCenter;
    display: inline-flex !important;

    &:hover {
        background-color: $cadetblue-color;
    }

    &.react-datepicker__day--keyboard-selected {
        background-color: $primary-color !important;
        color: $white-color;
    }
}

.react-datepicker__day-names {
    display: flex;
    justify-content: space-around;

    .react-datepicker__day-name {
        font-size: 12px;
        color: $woodsmoke-color;
        text-transform: uppercase;
        padding: 5px;
    }
}

.react-datepicker__navigation-icon::before {
    border-color: $woodsmoke-color !important
}

.clear-date {
    position: absolute;
    right: 60px;
    width: 16px;
    //top: 16px;
    top: 50%;
    transform: translateY(-50%);
}


.error-message {
    color: $form-error-border;
    font-size: 16px;
}