@import "base";

.breadcrumb {
    margin:0;
    li {
        font-size: 16px;
        line-height: 20px;

        &:before {
            color: $custom-link-color !important;
        }

        a {
            font-size: 16px;
            line-height: 20px;
            color: $custom-link-color;
            text-decoration: none;

            &:hover, &:hover {
                color: $custom-link-color;
            }
        }
    }
}

.arrow-breadcrumb {
    .breadcrumb-item {
        + .breadcrumb-item {
            &:before {
                content: '';
                height: 8px;
                width: 8px;
                border-right: 2px solid $nevada-color;
                border-top: 2px solid $nevada-color;
                transform: rotate(45deg);
                margin: 0 10px 0 0;
                display: inline-block;
                float: none;
                padding: 0;
            }
        }
    }
}