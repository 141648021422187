@import "base";

.page-content {
    height: 100vh;
    width: calc(100% - 72px);
    margin-left: 72px;
    //max-height: 100vh;
    background: #E7EBF2;
    /*padding-top:114px;*/

    .page-top-actions {
        @include FlexCenterCenter;
    }

    .page-title-section {
        padding: 20px 48px 40px;
        height: 145px;
        @include FlexCenterBetween;
        position: relative;
        /*position:fixed;
        top:0;
        width:calc(100% - 48px);*/
        /*p {
            color: $nevada-color;
            position: absolute;
            bottom: 16px;
            right: 48px;
            margin: 0 15px 0 0;
        }*/
    }

    &.futuresite-page {
        .page-title-section {
            height: 64px;
            padding: 24px 48px;
        }
    }

    .last-updated {
        color: $nevada-color;
        position: absolute;
        bottom: 16px;
        right: 48px;
        @include FlexCenter;

        p {
            margin: 0 10px 0 0;
        }

        .time-zone {
            @include FlexCenterCenter;
            color: $nevada-color;

            img {
                margin: 0 2px 0 0;
            }
        }
    }

    .content-left {
        position: relative;
        min-width: 338px;
        max-width: 338px;
        padding: 0;
        margin: 0 -24px 0 0;
    }

    .content-right {
        background: $white-color;
        padding: 8px;
        width: calc(100% - 300px);
        overflow: hidden;

        @include breakpoint (medium-desktop) {
            .page-title-section {
                padding: 24px 48px 48px;
            }
        }
    }

    @include breakpoint (portrait-tablet-end) {
        margin: 0;
        width: 100%;
        padding: 48px 0 0;

        .page-title-section {
            padding: 8px 16px;
            min-height: 48px;
            height: auto;

            a {
                display: flex;
            }

            h3, h3 span {
                margin: 0;
                font-size: 18px;
                line-height: 22px;
                font-weight: 700;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                max-width: 92%;
                display: inline-block;
                width: 100%;

                img {
                    position: inherit !important;
                    transform: inherit !important;
                    width: 8px;
                    height: 12px;
                    margin: 0 10px 0 0;
                    left: 0 !important;
                }
            }

            .back-arrow {
                display: none;
            }

            .page-top-left {
                @include FlexCenterBetween;
                width: 100%;
            }
        }

        .actions-icons {
            .list-group-item {
                background: none;
                padding: 2px;
                border: none;
                margin: 0 0 0 12px;
                width: 24px;
                height: 24px;

                &.plans-search {
                    position: fixed;
                    right: 16px;
                    top: 60px;
                }
            }
        }

        .last-updated {
            display: none;
        }
    }
}
