@import "base";

.alert {
    border: 0px;
    border-radius: 0;
    top: 25px;
    max-width: 895px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    color: $woodsmoke-color;
    padding: 16px 20px 16px 12px;
    margin: 0;
    border-left: 8px solid transparent;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: fixed;

    a {
        margin-top: 0;
    }

    /*.alert-icon {
        display: inline-block;
        width: 18px;
        height: 18px;
        margin: 0 8px 0 0;
        display: flex;
    }*/

    .btn-close {
        padding:0;
        right:13px;
        box-shadow:none;
        top: 50%;
        transform: translateY(-50%);

        &:hover {
            opacity: 0.8;
        }
    }

    &.alert-success {
        background: $success-border-color;
        border-color: $success-color;
    }

    &.alert-error, &.alert-warning {
        background: $error-border-color;
        border-color: $error-color;
    }
}