@import "base";

.fields-timer {
    @include FlexCenter;

    span {
        font-weight: 600;
    }
}

.progress {
    margin: 0 8px 0 0;
    width: 20px;
    height: 20px;

    .CircularProgressbar {
        border-radius: 100%;
        transform: rotateY(-180deg);
        width: 20px;
        height: 20px;
        background: $secondary-color;
        padding: 1px;

        .CircularProgressbar-path {
            stroke: $white-color
        }

        .CircularProgressbar-trail {
            stroke: $secondary-color;
        }
    }
}
