@import '../../scss/base.scss';

.info-section {
    min-height: 450px;
    color: $white-color;
    background-image: linear-gradient(to right bottom, rgba(0,73,122,0.8), rgba(0,73,122,0.8)), url(../../images/home-bg.jpg);
    background-size: cover;
    padding: 90px 0 40px;

    .info-inner-section {
        max-width: 1080px;
    }

    h1 {
        font-size: 52px;
        line-height: 64px;
        margin-bottom: 40px;
        padding-bottom: 25px;


        span {
            display: block;
            font-size: 52px;
            line-height: 64px;
        }

        &:after {
            background: $secondary-color;
            height: 4px;
            width: 84px;
            position: absolute;
            left: 50%;
            right: auto;
            content: '';
            transform: translateX(-47%);
            bottom: 0;
        }
    }

    p {
        padding: 0 100px;
        margin: 0 0 24px;
    }

    button, a {
        min-width: 175px;
        padding: 14px 39px;
        line-height: 18px;
        font-weight: 700;
        border: none;
    }

    @include breakpoint (desktop-tablet-middle) {
        h1, h1 span {
            font-size: 44px;
            line-height: 56px;
        }

        p {
            padding: 0 32px;
        }
    }

    @include breakpoint (portrait-tablet-end) {
        h1, h1 span {
            font-size: 32px;
            line-height: 42px;
        }
    }

    @include breakpoint (mobile) {
        h1, h1 span {
            font-size: 24px;
            line-height: 32px;
        }

        p {
            padding: 0 16px;
        }
    }
}
